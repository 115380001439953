/**
 * OOTB code. Added .footer-links, email info & social group.
  */

@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        // LP customization
        .email-info-#{$size} {
            order: -1;
        }

        .social-group-#{$size} {
            display: none;
        }

        // LP customization

        .collapsible-#{$size} {
            .title {
                display: inline-block;
                line-height: 2.5rem;

                @include clearfix;

                &::after {
                    content: '';
                    transform: rotate(45deg);
                    height: rem(12);
                    width: rem(12);
                    border-bottom: rem(1) solid $grey-text;
                    border-right: rem(1) solid $grey-text;
                    right: 0;
                    top: rem(2);
                    cursor: pointer;
                    margin-left: rem(16);
                    margin-top: rem(10);
                    transition: all 0.3s ease;
                }
            }

            // LP customization
            .footer-links {
                display: block;
                bottom: 0;
                margin-top: rem(32);
                text-decoration: none;
            }

            // LP customization

            &.active {
                .title::after {
                    content: '';
                    margin-top: rem(16);
                    transform: rotate(225deg);
                    top: rem(9);
                }

                .content,
                .card-body {
                    display: block;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        color: $base-text-color;
        text-decoration: none;
        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }
}
