.merge-baskets-dialog {
    &.modal {
        padding: 0 1rem;

        .modal-dialog {
            top: 50%;
            margin: 0 auto;
            transform: translateY(-50%);
        }

        .modal-content {
            border: 0;
            padding: rem(16) rem(24) rem(24);

            .modal-body {
                text-align: center;
                line-height: 1.5;
            }
        }

        .merge-baskets-dialog-heading {
            margin-bottom: 0;
            padding: 0;
            font-family: $gotham-semibold;
            font-size: rem(16);
            line-height: rem(24);
            color: $link-color;
        }

        .merge-baskets-dialog-cancel-btn {
            margin-right: rem(12);
        }
    }
}
@include media-breakpoint-up(md){
    .merge-baskets-dialog{
        .modal-dialog{
            .modal-content{
                .wishlist-modal-btn-primary,
                .wishlist-modal-btn-secondary{
                    position: initial;
                }
            }
        }
    }
}