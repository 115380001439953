.cart {
    .item-attributes {
        line-height: rem(16);
        margin: 0 rem(16);
        padding: 0;
    }

    .gwp-title {
        text-transform: uppercase;
    }

    .line-item-attributes,
    .line-item-option,
    .quantity-value {
        font-size: rem(14);
        font-family: $gotham-regular;
        line-height: rem(20);
        color: $primary-black;

        p {
            margin-bottom: 0;
        }
    }

    .line-item-name {
        white-space: nowrap;
        color: $primary-black;
        font-family: $crimson;
        font-size: rem(18);
        line-height: rem(24);
        font-weight: normal;
        width: 100%;

        .item-quantity {
            font-size: rem(14);
        }

        .item-message {
            font-size: rem(12);
        }

        span {
            max-width: 100%;
            word-wrap: wrap;
        }

        a {
            text-decoration: none;
        }
    }

    .line-item-pricing-info {
        margin-bottom: 0;

        + .price {
            font-size: 1em;
            font-weight: bolder;
        }
    }

    .item-image {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;

        img.product-image {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
            margin-left: 0;
        }
    }

    .price {
        word-wrap: nowrap;
        padding: 0 rem(16);
        margin: 0;
        padding-left: 0;
    }

    .line-item-total-price-amount {
        color: $emperor;
        font-family: $gotham-semibold;
        font-weight: normal;
        font-size: rem(14);
        line-height: rem(20);
    }

    .product-line-item-details {
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;

        .price {
            word-wrap: nowrap;
            padding: 0 rem(16);
            margin: 0;
            padding-left: 0;
        }

        .item-total-null {
            color: $product-total-price-color;
            font-family: $gotham-semibold;
            font-weight: normal;
            font-size: rem(14);
            line-height: rem(20);
        }
    }
}

@include media-breakpoint-down(md) {
    .receipt {
        .product-line-item-details {
            .line-item-header {
                >.price {
                    margin-top: rem(4);
                }
            }
            .item-status-container, .product-item-shipping-details {
                .line-item-text, .value {
                    display: block;
                    margin-left: 0;
                }
                .tertiary-link {
                    font-size: inherit;
                }
            }
        }
    }
}
