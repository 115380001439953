// Buttons
$btn-font-family: $font-primary;
$btn-font-size: $font-size-14;
$btn-font-weight: $font-weight-medium;
$tertiary-link-font-size: $font-size-3;
$tertiary-link-font-weight: $font-weight-medium;

.btn {
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    line-height: rem(48);
    letter-spacing: 0.08em;
    padding: 0;
    border-radius: 0;
    border: rem(1) solid transparent;
    height: rem(48);
    text-transform: uppercase;

    &:focus,
    &:focus-visible,
    &:visited,
    &:active{
        border: rem(0.5) solid $primary-black;
        box-shadow: none;
    }

    @include media-breakpoint-up(md) {
        line-height: rem(48);
        height: rem(48);
    }

    // custom button - primary
    &.button-primary {
        @include button(
            $bg: $btn-primary-bg,
            $color: $btn-primary-color,
            $border-color: $btn-primary-border-color,
            $hover-bg: $btn-primary-hover-bg,
            $hover-color: $btn-primary-hover-color,
            $hover-border-color: $btn-primary-hover-border-color,
            $active-bg: $btn-primary-active-bg,
            $active-color: $btn-primary-active-color,
            $active-border-color: $btn-primary-active-border-color );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $submit-form-disable-grey, $color: $btn-primary-disabled-color, $border-color: $submit-form-disable-grey);
        }
        &:focus,
        &:focus-visible,
        &:visited,
        &:active{
            outline: rem(0.5) solid $primary-black;
            box-shadow: none;
            
        }
    }

    // custom button - secondary
    &.button-secondary {
        @include button($bg: $btn-secondary-bg,
            $color: $btn-secondary-color,
            $border-color: $btn-secondary-border-color,
            $hover-bg: $btn-secondary-hover-bg,
            $hover-color: $btn-secondary-hover-color,
            $hover-border-color: $btn-secondary-hover-border-color,
            $active-bg: $btn-secondary-active-bg,
            $active-color: $btn-secondary-active-color,
            $active-border-color: $btn-secondary-active-border-color );

        &.disabled,
        &:disabled {
            opacity: 1;
            pointer-events: none;
            @include button($bg: $btn-secondary-bg, $color: $submit-form-disable-grey, $border-color: $submit-form-disable-grey);
        }
        &:focus,
        &:focus-visible,
        &:visited,
        &:active{
            outline: rem(0.5) solid $primary-black;
            box-shadow: none;
            
        }
    }
}

.tertiary-link {
    color: $tertiary-link-font-color;
    font-family: $btn-font-family;
    font-size: $tertiary-link-font-size;
    font-weight: $tertiary-link-font-weight;
    line-height: rem(20);
    letter-spacing: 0.08em;
    border: 0;
    background: $tertiary-link-bg;

    &:hover {
        color: $tertiary-link-hover-font-color;
    }

    &:active {
        color: $tertiary-link-active-font-color;
    }

    &:disabled {
        opacity: 1;
        pointer-events: none;
        color: $tertiary-link-disabled-font-color;
    }
    &:focus,
    &:focus-visible,
    &:visited,
    &:active{
        outline: rem(0.5) solid $primary-black;
        
    }
}

button.tertiary-link{
    &:focus,
    &:focus-visible,
    &:visited,
    &:active{
    outline: rem(0.5) solid $primary-black;
    }
}

a {
    &:focus-visible,
    &:focus{
        outline: rem(0.5) solid $primary-black;
    }
}