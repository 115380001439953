.fsp-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up (lg) {
        margin-top: rem(24);
        padding-left: rem(8);
    }

    .size-chart {
        margin-left: auto
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        .size-chart {
            margin-left: inherit;
        }
    }

    .fp-root {
        margin: 0;
        padding-left: rem(5);

        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }
}