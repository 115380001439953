// Base font, color setting
body {
    font-family: $gotham-regular;
    font-size: $font-size-14;
    color: $grey-text;
    line-height: rem(16);
    background-color: $white;
    -webkit-font-smoothing: antialiased;

    // to override Global-e inline styles (for win only)
    @include media-breakpoint-up(xl) {
        .win-magic & {

            &[style*="overflow: visible hidden"],
            &[style*="overflow-y: hidden;"] {
                padding-right: rem(17) !important;
            }
        }
    }
}

article,
aside,
figcaption,
figure,
footer,
hgroup,
section,
body {
    overflow-x: hidden;
}

p {
    font-family: $gotham-regular;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
}

.svg-icon {
    pointer-events: none;
}

button {
    background: none;
    border: 0;
    color: $primary-black;
    box-shadow: none;
}


.btn-primary,
.btn-secondary,
.tertiary-link {

    &:focus,
    &:focus-visible,
    &:visited,
    &:active {
        outline: rem(0.5) solid $primary-black;
    }
}

.custom-checkbox .custom-control-input:focus+label,
.custom-radio .form-check-input:focus+label {
    border: rem(0.5) solid $primary-black;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.veil {
    z-index: $zindex-max-highest;
}

.svg-wishlist-active-icon {
    fill: $primary-black;
}

// Headings Avenir
h1,
.heading-1 {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-7;
    line-height: rem(60);
    letter-spacing: 0.1em;
}

h2,
.heading-2 {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-40;
    line-height: rem(48);
    letter-spacing: 0.05em;
}

h3,
.heading-3 {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-6;
    line-height: rem(36);
    letter-spacing: 0.08em;
}

// Titles Avenir
.title1-medium {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-5;
    line-height: rem(32);
    letter-spacing: 0.08em;
}

.title1-regular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-5;
    line-height: rem(32);
    letter-spacing: 0.05em;
    color: $primary-black;
}

.title2-demiBold {
    font-family: $font-demi;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-4;
    line-height: rem(24);
    letter-spacing: 0.08em;
    color: $primary-black;
}

.title2-medium {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-4;
    line-height: rem(24);
    letter-spacing: 0.08em;
}

.title3-medium {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-3;
    line-height: rem(20);
    letter-spacing: 0.08em;
}

.title4-demiBold {
    font-family: $font-primary;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: 0.08em;
}

.title4-medium {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: 0.08em;
}

.title-4-regular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: 0.08em;
}

.title5-medium {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-12;
    line-height: rem(16);
    letter-spacing: 0.08em;
}

.title5-regular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
    line-height: rem(16);
    letter-spacing: 0.08em;
}

// Body Copy Avenir
.body1-largeRegular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-3;
    line-height: rem(24);
    letter-spacing: 0.05em;
    text-indent: rem(1);
}

.body2-mediumRegular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: 0.05em;
}

.body2-mediumRegular2 {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: rem(25);
    letter-spacing: 0.1em;
    text-indent: rem(1);
}

.body3-smallRegular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
    line-height: rem(16);
    letter-spacing: 0.05em;
    text-indent: rem(2);
}

.body4-extrasmallRegular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-10;
    line-height: rem(12);
    letter-spacing: 0.05em;
}

.body4-extrasamllDemibold {
    font-family: $font-primary;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-10;
    line-height: rem(12);
    letter-spacing: 0.05em;
}

.body4-extrasmall {
    font-weight: $font-weight-regular;
    font-size: $font-size-10;
    line-height: rem(12);
    letter-spacing: 0.05em;
}

a.outline-link,
button.outline-link {

    &:focus,
    &:focus-visible,
    &:visited,
    &:active {
        outline: rem(0.5) solid $primary-black;
    }
}

.afterpay-paragraph {
    img.afterpay-logo {
        margin-left: 0;
    }
}

a.sms-disclaimer {
    text-decoration: underline;
}
