.back-to-page-link,
.back-to-account-link {
    font-size: $font-size-12;
    line-height: rem(16);
    font-family: $font-primary;
    color: $breadcrumb-profile-page-text-color;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: rem(12);
    letter-spacing: 0.05em;

    &::after {
        width: rem(6);
        height: rem(6);
        content: '';
        border-top: 1px solid $breadcrumb-profile-page-border-color;
        border-left: 1px solid $breadcrumb-profile-page-border-color;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: rem(5)
    }

    &:focus,
    &:focus-visible {
        outline: rem(0.5) solid $primary-black;
    }
}

.container-fluid {
    max-width: rem(1440);
    margin: 0 auto;

    &.back-arrow-container {
        padding: rem(24) rem(80) 0 rem(80);

        @include media-breakpoint-down(sm) {
            padding: rem(12) rem(12) rem(24) rem(12);
        }

        .chevron-left-icon {
            width: rem(3);
            height: rem(5.5);
        }

        .back-to-account {
            font-size: $font-size-12;
            line-height: rem(16);
            font-family: $font-primary;
            color: $breadcrumb-profile-page-text-color;
            text-decoration: none;
            display: inline-block;
            position: relative;
            padding-left: rem(8);
            top: rem(1);
            letter-spacing: 0.05em;
        }
    }
}

.back-arrow-container {
    padding: 0 rem(16);

    @include media-breakpoint-up(md) {
        padding: 0 rem(80);
    }

    @include media-breakpoint-only(md) {
        padding: 0 rem(16);
    }

    .back-to-account-link {
        margin-top: rem(12);

        @include media-breakpoint-up(md) {
            margin-top: rem(24);
        }
    }
}

.wishlist-breadcrumb,
.category-breadcrumb,
.product-breadcrumb {
    padding: 0 rem(12);

    @include media-breakpoint-up(md) {
        padding: 0 rem(16);
    }

    .breadcrumb {
        color: $primary-black;
        letter-spacing: 0.08em;
        text-transform: capitalize;
        margin-bottom: rem(24);
        padding-top: rem(16);
    }

    .breadcrumb-item {
        color: $primary-black;

        a {
            color: $primary-black;
        }
    }

    .breadcrumb-item+.breadcrumb-item {
        padding-left: rem(16);
        position: relative;

        &::before {
            position: absolute;
            content: '';
            transform: rotate(-45deg);
            height: rem(6);
            width: rem(6);
            border-bottom: rem(1) solid $primary-black;
            border-right: rem(1) solid $primary-black;
            right: 0;
            left: rem(3);
            top: rem(5);
            transition: all 0.3s ease;
            background: none;
            padding-right: 0;
        }
    }
}