@import "../utilities/swatch";
@import "productCommon";
@import "quickView";

.product-tile {

    @include media-breakpoint-down(md) {
        min-height: 23.4375em;
    }

    @include media-breakpoint-down(sm) {
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
    }

    border: 0;
    margin-bottom: 0;

    .tile-body {
        padding: 0.625em 0 1.875em;

        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: 1.125em;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: 1.0625em;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1em;
            }

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            line-height: 1.2;

            a {
                font-size: 1em;
                text-decoration: none;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .ratings {
            font-size: 0.9em;
        }
    }

    .image-container {
        position: relative;
        overflow: auto;

        .quickview {
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: block;

            .tile-image {
                width: 100%;
            }
        }
    }

    .swatches {
        a {
            text-decoration: none;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(1.8em, $white);
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}
