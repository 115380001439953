.notify-me-container {
    color: $emperor;
    font-family: $gotham-regular;
    margin: rem(8);

    .notify-product-img {
        padding: 0 rem(64);

        img {
            width: 100%;
            padding: 0 rem(6) rem(2) rem(6);
        }
    }

    .product-desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: rem(32);
        margin-bottom: rem(16);

        .notify-product-name {
            font-family: $font-medium;
            font-size: $font-size-3;
            line-height: rem(20);
            font-weight: $font-weight-medium;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: $primary-black;
        }

        .notify-price {
            font-size: $font-size-14;
            line-height: rem(20);
            font-family: $font-medium;
            font-weight: $font-weight-medium;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: $primary-black;
            padding: rem(2) 0;
        }
    }

    .notify-size {
        font-size: rem(14);
        line-height: rem(20);
        padding: rem(2) 0;
    }

    .notify-me-desc {
        font-size: $font-size-12;
        letter-spacing: 0.05em;
        font-weight: $font-weight-regular;
        line-height: rem(16);
        margin-top: rem(32);
        color: $primary-black;
    }

    .input-group {
        margin-top: rem(16);
        padding: rem(12) 0 0;

        .notify-email {
            width: 100%;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $boulder;
            outline: 0;
            font-size: rem(16);
            @include media-breakpoint-up(md) {
                font-size: rem(14);
            }

            line-height: rem(20);
            color: $emperor;
            padding: rem(7) 0 0;
            background: transparent;
            transition: border-color 0.2s;

            &::placeholder {
                color: transparent;
            }

            &:placeholder-shown ~ .notify-email-label {
                font-size: rem(14);
                line-height: rem(20);
                cursor: text;
                top: rem(20);
            }

            ~ .notify-email-label,
            &:focus ~ .notify-email-label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: $font-size-12;
                line-height: rem(16);
                color: $boulder;
            }

            &:focus ~ .notify-email-label {
                color: $emperor;
            }

            &:focus {
                border-bottom: 1px solid $emperor;
            }

            &.text-input-error {
                font-family: $gotham-regular;
                color: $error-red;
                border-color: $error-red;
            }
        }
    }

    .email-error {
        font-family: $gotham-regular;
        font-size: rem(10);
        line-height: rem(16);
        color: $error-red;
        margin-top: rem(8);
    }

    .checkbox-group,
    .notify-me-submit {
        margin-top: rem(24);
    }

    .checkbox-group {
        position: relative;
        padding-left: rem(24);

        .notify-checkbox-label {
            font-size: rem(12);
            margin-bottom: 0;
            letter-spacing: rem(0.4);
        }
    }

    .notify-me-confirm {
        font-size: rem(12);
        line-height: rem(16);
        margin-top: rem(16);
        letter-spacing: rem(0.4);
    }

    .footer-close {
        margin-top: rem(40);
        text-align: center;

        .footer-close-link {
            font-size: rem(12);
            line-height: rem(24);
            letter-spacing: rem(0.4);
            text-decoration: underline;
        }
    }

    .notify-form {
        .notify-me-checkbox-section {
            margin: rem(26) 0 0 rem(24);
        }
        .wishlist-input-group {
            .notify-me-checkbox-label {
                position: unset;
            }
        }
        .wishlist-notify-me-submit {
            position: unset;
            margin-top: rem(32);
            font-size: $font-size-3;
            line-height: rem(20);
            font-weight: $font-weight-medium;
            font-family: $font-medium;
            letter-spacing: 0.08em;
            text-transform: uppercase;
        }
    }

    .input-group.wishlist-input-group {
        margin-top: rem(24);
    }
}

@include media-breakpoint-up(md) {
    .notify-me-container {
        margin: rem(8) 0 0 0;

        .product-desc {
            flex-direction: row;
            justify-content: space-between;
            margin: rem(40) 0 0 0;

            .notify-product-name {
                padding: 0 0 rem(16) 0;
            }

            .notify-price {
                font-size: $font-size-14;
                line-height: rem(20);
                padding: 0 0 rem(16) 0;
            }
        }

        .notify-size {
            padding: 0;
        }


        .input-group {
            margin-top: rem(48);
        }

        .notify-me-confirm {
            font-size: rem(14);
            line-height: rem(20);
            margin-top: rem(24);
            letter-spacing: normal;
        }

        .footer-close-link {
            font-size: rem(12);
        }
    }
}
