@import '../global/global';

.checkout-login-title.page-title {
    position: static;
    color: $page-title-color;
    font-family: $crimson;
    font-size: rem(32);
    line-height: rem(40);
    background-color: $custom-control-background-color;
    margin: rem(78) 0 rem(48) 0;
    padding: 0;

    &::before {
        background-color: $page-title-background-color;
    }
}

h1.page-title {
    position: static;
    color: $page-title-color;
    font-family: $crimson;
    font-size: rem(32);
    line-height: rem(40);
    background-color: $custom-control-background-color;
    padding: 0;
    margin: rem(32) 0 rem(48);

    @include media-breakpoint-up(md) {
        margin: rem(24) 0 rem(32);
    }

    &::before {
        background-color: $page-title-background-color;
    }

    &.plp-title {
        font-family: $font-primary;
        color: $primary-black;
        font-size: rem(20);
        line-height: rem(24);
        letter-spacing: 0.05em;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: rem(24);
            line-height: rem(28);
            letter-spacing: 0.08em;
        }
    }
}