@import './collapsibleItem';

.footer-container {
    padding-top: rem(24);
    max-width: rem(1440);

    @include media-breakpoint-up(md) {
        padding-left: 4.42%;
        padding-right: 4.42%;
    }

    .rendering-template {
        padding-left: rem(12);

        @include media-breakpoint-up(md) {
            padding-left: rem(16);
        }
    }
}

footer {
    background-color: $footer-backgroud-color;
    padding-top: 0;
    padding-bottom: rem(44);

    @include media-breakpoint-up(md) {
        padding-bottom: rem(18);
    }

    .content-cols {
        padding-bottom: rem(30);

        @include media-breakpoint-up(md) {
            padding-bottom: rem(32);
        }
    }

    hr {
        border-top: rem(0.5) solid $primary-black;
    }

    .footer-section-title {
        font-family: $font-medium;
        font-size: $font-size-12;
        font-weight: $font-weight-medium;
        line-height: rem(16);
        color: $primary-black;
        margin-bottom: rem(12);
        border-bottom: rem(0.5) solid $primary-black;
        text-transform: uppercase;
        padding-bottom: rem(8);
        margin-bottom: rem(24);
        letter-spacing: 0.08em;
    }

    .footer-contact-details {
        font-family: $font-primary;
        font-size: $font-size-12;
        font-weight: $font-weight-medium;
        color: $primary-black;
        margin-bottom: rem(12);
        line-height: rem(16);
        margin-top: rem(24);

        .contact-footer {
            &:focus {
                border: rem(0.5) solid $primary-black;
            }
        }

        .outline-link {
            font-weight: $font-weight-regular;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .footer-section-message {
        line-height: rem(20);
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .phone-icon,
    .mail-icon,
    .sms-icon {
        margin-right: rem(8);
    }

    .sms-icon {
        vertical-align: text-top;
    }

    .follow-title {
        margin-bottom: rem(16);
    }

    .social-link-icons {
        @include clearfix;

        @include media-breakpoint-down(md) {
            width: 80%;
            margin-top: rem(4);
        }

        display: flex;
        position: relative;
        z-index: $zindex-lowest;

        li {
            margin-right: rem(16);

            .svg-icon {
                width: rem(24);
                height: rem(24);
            }
        }

        a {
            display: inline-block;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .customer-service {
        margin-top: rem(-8);
    }

    .footer-links {
        display: flex;
        position: relative;
        float: right;

        li {
            font-size: $font-size-12;
        }
    }

    .content {
        font-family: $font-primary;
        font-size: $font-size-12;
        line-height: rem(20);
        margin-bottom: rem(16);

        li {
            line-height: rem(16);
            margin-top: rem(8);
            font-size: $font-size-12;
            height: auto;
            margin-bottom: rem(16);

            a {
                color: $primary-black;
                letter-spacing: rem(0.5);

                &:focus {
                    outline: rem(0.5) solid $primary-black;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer-email-signup {
        background-color: $footer-backgroud-color;

        .input-group-append {
            margin-top: rem(24);
            margin-bottom: rem(32);
        }
    }

    .modal {
        .modal-header {
            border: 0;
            padding: 0;

            .close {
                margin-top: rem(24);
                margin-right: rem(40);
                margin-bottom: rem(8);
                padding: 0;
            }
        }

        .success-modal-title {
            color: $base-text-color;
            font-family: $font-primary;
            font-size: $font-size-3;
            line-height: rem(24);
            text-align: center;
        }

        .success-modal-body {
            color: $base-text-color;
            font-family: $gotham-regular;
            font-size: $font-size-14;
            line-height: rem(20);
            text-align: center;
            margin: rem(8) rem(40) rem(48) rem(40);
        }
    }

    .aetna-li {
        line-height: 1rem !important;
        padding-top: 0.425rem;
    }

    /*nav dropdown removed extra padding*/
    .multilevel-dropdown .dropdown-item {
        padding: 0 0 0 0em !important;
    }

    @media only screen and (max-width:1024px) {
        .aetna-li {
            line-height: 1.5rem !important;
            padding-top: 0.2rem;
        }
    }

    @media only screen and (max-width:767px) {
        .aetna-li {
            line-height: 1.875rem !important;
            padding-top: 0rem;
        }
    }

    .footer .social-link-icons a {
        fill: $emperor;
    }

    .footer-url {
        display: flex;
        margin-left: rem(0);
        line-height: rem(12);
        margin-top: rem(32);
    }

    .footer-url .info-pages {
        font-size: $font-size-10;
        padding-right: 1rem;
        text-transform: uppercase;
        margin-top: rem(10);
        line-height: rem(8);
    }

    .footer-links span {
        margin-right: rem(12);
        text-decoration: none;
        line-height: rem(30);
        margin-right: rem(16);
    }

    .footer-privacy-choices-icon {
        display: inline-block;
        height: rem(16);
        margin-left: rem(6);
    }

    .only-desktop {
        display: block;
        margin-top: rem(8);
    }

    .copyright-footer-txt {
        font-family: $font-primary;
        font-size: $font-size-10;
        color: $primary-black;
        margin-right: rem(24);
        letter-spacing: 0.05em;
        margin-left: 0;
    }

    .footer-purchases-text {
        display: none;
    }

    #onetrust-privacy-dialog iframe {
        height: calc(100% - rem(174)) !important;
    }

    #onetrust-pc-sdk #ot-pc-content {
        padding-bottom: 1.5rem !important;
    }

    .cookie-preferences {
        text-transform: uppercase;
        text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
        .footer {
            .social-link-icons {
                width: 100%;
            }
        }

        .mobile {
            margin-top: rem(-8);
        }

        .content {
            .newsletter {
                margin-bottom: rem(20);
            }
        }

        .footer-customer-service {
            .footer-section-title {
                margin-top: rem(16);
            }
        }

        .footer-newsletter {
            .footer-section-title {
                margin-top: rem(16);
            }
        }

        .footer-connect {
            .footer-section-title {
                margin-top: rem(14);
            }
        }
    }

    @include media-breakpoint-down(xl) {
        #onetrust-privacy-dialog iframe {
            height: calc(100% - rem(125)) !important;
        }
    }

    @include media-breakpoint-down(lg) {
        .collapsible-md .footer-links {
            margin: rem(-7) rem(4);
            width: 100%;
            padding-left: 0;
            margin-left: 0;
        }

        .content-asset {
            flex: 1;
        }

        .social-links {
            display: flex;

            li {
                width: auto;
            }
        }
    }

    @include media-breakpoint-between(md, lg) {
        .collapsible-md .title::after {
            display: none;
        }

        .collapsible-md .content {
            display: initial;

            &.footer-links {
                display: flex;
                flex-direction: column;
                margin-left: 0;
            }
        }
    }

    .shipping-footer-banner {
        text-align: left;
        background-color: $light-clay;
        padding-top: rem(40);
        padding-bottom: 0.5rem;
        margin: auto;

        &.row {
            margin: 0;
        }
    }

    .cta-link-oh {
        color: $primary-black;
        text-decoration: underline;
    }

    .shipping-footer-content {
        margin: 0 auto;
        padding-left: rem(12);
        padding-right: rem(12);

        @include media-breakpoint-up(lg) {
            max-width: rem(1000);
        }

        @include media-breakpoint-up(xl) {
            max-width: rem(1108);
        }
    }

    .footer-shipdelay-title {
        padding-bottom: 1rem;
    }

    .footer-shipdelay-content {
        color: $primary-black;
        font-family: $font-primary;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        letter-spacing: 0.05em;
        line-height: rem(20);
    }

    .footer-ship-icon {
        float: left;
        height: rem(34);
        margin-right: rem(16);
        margin-top: -1rem;
        width: rem(50);
    }

    .footer-ship-note {
        color: $primary-black;
        display: block;
        font-family: $font-medium;
        font-size: $font-size-14;
        font-weight: $font-weight-medium;
        letter-spacing: 0.08em;
        line-height: rem(20);
        text-transform: uppercase;
    }
}