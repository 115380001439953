// Global common classes

.no-scroll {
    overflow: hidden !important;
}

.text-underline {
    text-decoration: underline;
}

.font-gotham-regular {
    font-family: $gotham-regular;
}

.font-crimson {
    font-family: $crimson;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.zindex-0 {
    z-index: $zindex-zero;
}

.zindex-1 {
    z-index: $zindex-lowest;
}

.relative {
    position: relative;
}

.no-pointer-events {
    pointer-events: none;
}

.sticky-top-nav {
    --top: #{$header-height};

    position: sticky;
    top: var(--top);
    align-self: flex-start;
    transition: all 0.3s;
}

.margin-auto {
    margin: auto;
}

.no-underline {
    text-decoration: none !important;
}
