.csrf-error-modal {
    .modal-dialog {
        max-width: rem(328);
        margin: 0 auto;

        .modal-content {
            text-align: center;
        }

        .modal-body {
            padding-top: rem(8);
            margin-bottom: rem(16);
        }
    }

    .modal-title {
        font-family: $gotham-semibold;
        font-size: rem(16);
        line-height: rem(24);
    }

    .modal-description {
        font-family: $gotham-regular;
        font-size: rem(14);
        line-height: rem(20);
        margin: auto rem(20) rem(24);
    }

    .reload-button {
        max-width: rem(276);
        margin: 0 auto;
    }

    @include media-breakpoint-up(md) {
        .modal-dialog {
            max-width: rem(480);
        }
    }
}
