.approaching-discounts {
    margin-top: rem(16);

    .card-body {
        padding: 0;
    }

    .card {
        .card-header {
            padding: rem(8) 0;
        }

        .show-more-less-button {
            width: auto;
            padding-right: rem(20);

            .collapsed {
                display: none;
            }

            .expanded {
                display: inline;
            }
        }

        .collapsed {
            .collapsed {
                display: inline;
            }

            .expanded {
                display: none;
            }
        }
    }

    &.multiple-approaching-discounts {
        .single-approaching-discount {
            list-style: disc;
            margin-left: rem(16);
        }
    }

    .single-approaching-discount {
        border: none;
        background: none;
        color: initial;
        font-family: $gotham-semibold;
        font-size: rem(12);
        line-height: rem(16);
        letter-spacing: rem(0.4);
        margin-bottom: rem(8);

        a {
            font-family: $gotham-regular;
            font-size: rem(12);
            line-height: rem(24);
            letter-spacing: rem(0.4);
            text-decoration: underline;
        }
    }
}
