.recommendations {
    margin: rem(40) auto rem(40);

    @include media-breakpoint-up(md){
        margin: rem(40) auto rem(56);
    }

    .homepage & {
        margin: rem(30) auto rem(20);

        @include media-breakpoint-up(md){
            margin: rem(60) auto rem(40);
        }
    }

    &.heading-styling {
        margin-bottom: rem(56);

        @include media-breakpoint-up(xxl) {
            max-width: rem(1280);
        }
    }

    .product-tile {
        @include media-breakpoint-down(md) {
            min-height: unset;
        }

        .image-container {
            min-height: auto;
        }

        .product-tile-badge {
            bottom: rem(8);
        }
    }

    .title {
        width: 100%;
        font-family: $font-medium;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        font-size: rem(20);
        line-height: rem(24);
        letter-spacing: 0.08em;
        text-align: center;
        margin-bottom: rem(40);
        padding: 0;
        letter-spacing: 0.08em;

        @include media-breakpoint-up(lg) {
            margin-bottom: rem(56);
            font-size: rem(20);
            line-height: rem(24);
        }
    }

    &-image {
        width: 100%;
    }

    &-products {
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
        }
    }

    &-products-tile {
        padding: 0 rem(4);

        &.col-6 {
            @include media-breakpoint-down(sm) {
                padding: 0 rem(4);
                flex: 0 0 45%;
                max-width: 45%;
            }
        }
    }

    .content-center {
        justify-content: normal;
    }

    @include media-breakpoint-up(md) {
        .content-center {
            justify-content: center;
        }
    }

    div[id^='cq_recomm_slot'] {
        width: 100%;
    }

    .grid-tile {
        padding: 0 rem(6);

        @include media-breakpoint-up(md) {
            padding: 0 rem(8);
        }
    }

    .scrollable-container {

        .scroll-left-arrow,
        .scroll-right-arrow {
            top: 60.5%;
        }
    }
    .product-recommendations{
        .scroll-bar{
            padding-bottom: rem(17);

            @include media-breakpoint-up(md){
                padding-bottom: rem(50);
            }
        }
    }
    .product-tile {

        .carousel-control-next:focus,
        .carousel-control-prev:focus {
            &:focus {
                height: 25%;
                opacity: 1;
                outline: none;
            }
        }
    }
}

.product-tile,
.quickview-container {
    .tile-img-link {
        &:focus {
            border: rem(1) solid $mulled-wine;
        }
    }
}

.product-listing-button {
    margin-top: rem(24);

    @include media-breakpoint-up(md) {
        margin-top: rem(40);
    }
}

.wishlist-recommendations {
    .add-to-wish-list,
    .loyalty-early-access-locked {
        position: absolute;
        top: rem(8);
        right: rem(8);
    }
    .svg-wishlist-icon, .svg-wishlist-active-icon {
        width: rem(11.6);
        height: rem(10.2);
        @include media-breakpoint-up(md) {
            width: rem(17.4);
            height: rem(15.3);
        }
    }
}

// einstein recommendations carousel via PD
.scrollable-container .einstein-carousel-new.scrollable-content {
    margin: 0 0 rem(60);
    padding: 0 rem(14);

    @include media-breakpoint-up(md) {
        padding: 0 rem(40);
    }

    .grid-tile {
        padding: 0 rem(6);
        flex: 0 0 45%;
        max-width: 45%;
        margin-bottom: rem(15);
        
        @include media-breakpoint-up(md) {
            padding: 0 rem(10);
            flex: 0 0 32%;
            max-width: 32%;
        }

        .tile-img-link {
            height: auto !important;
        }

        .tile-body {
            padding-bottom: 0;

            .price {
                min-height: auto;

                .sale-selected {
                    display: none;
                }
            }

            .product-tile-swatch {
                @include media-breakpoint-down(sm) {
                    margin-right: rem(5);
                }

                @include media-breakpoint-only(md) {
                    margin-right: rem(11);
                }
            }
        }
    }
}