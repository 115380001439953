@import './variables';

$form-group-input-text-font-family: $font-primary;
$form-group-placeholder-font-family: $font-primary;
$form-group-label-font-family: $font-primary;
$form-group-error-font-family: $font-primary;
$form-group-input-label-font-family: $font-primary;

.form-group.custom,
.input-group.custom {
    position: relative;
    margin-top: rem(32);
    margin-bottom: 0;

    .form-control {
        display: block;
        height: rem(48);
        border: none;
        border-radius: 0;
        font-size: $font-size-3;
        line-height: rem(16);
        letter-spacing: 0.05em;
        font-weight: $font-weight-regular;
        color: $primary-black;
        @include media-breakpoint-up(md) {
            font-size: $font-size-12;
            line-height: rem(16);
        }

        font-family: $font-primary;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border-bottom: rem(0.5) solid $primary-grey;
        position: relative;
        z-index: inherit;
        float: inherit;
        width: 100%;
        margin-bottom: 0;
        padding-top: rem(24);
        color: $primary-black;

        &::placeholder {
            font-size: $font-size-3;
            line-height: rem(16);
            font-family: $form-group-placeholder-font-family;

            @include media-breakpoint-up(md) {
                font-size: $font-size-12;
            }
        }

        &:focus,
        &:-webkit-autofill {
            border-bottom: rem(1) solid $primary-black;

            & ~ .float-label {
                top: rem(0);
                margin-bottom: rem(12);
                font-size: $font-size-3;
                color: $form-group-input-label-color;
                line-height: rem(16);
                @include media-breakpoint-up(md) {
                    font-size: $font-size-12;
                }
            }
        }

        &:disabled{
            color: $clay;
            border-bottom: rem(0.5) solid $disabled;

            & ~ .float-label {
                color: $disabled;
            }
        }

        &.is-invalid {
            background-image: none;

            + label {
                top: rem(0);
                margin-bottom: rem(12);
                font-size: $font-size-3;
                color: $form-group-label-color;
                line-height: rem(16);
                font-weight: $font-weight-regular;
                @include media-breakpoint-up(md) {
                    font-size: $font-size-12;
                }
            }
        }
    }

    label {
        position: absolute;
        top: rem(0);
        margin-bottom: rem(12);
        color: $form-group-label-color;
        font-size: $font-size-3;
        line-height: rem(20);
        font-family: $form-group-label-font-family;
        transition: 0.2s ease all;
        @include media-breakpoint-up(md) {
            font-size: $font-size-12;
        }
    }

    .float-label {
        left: 0;
        top: rem(20);
        font-size: $font-size-12;
        line-height: rem(20);
        font-family: $form-group-input-label-font-family;
        pointer-events: none;
        width: 100%;
        letter-spacing: 0.05em;
        font-weight: $font-weight-regular;
    }
    /* active state */
    input:focus ~ .float-label,
    input:not(:placeholder-shown) ~ .float-label,
    input:-webkit-autofill {
        top: rem(0);
        margin-bottom: rem(12);
        font-size: $font-size-3;
        color: $form-group-input-label-color;
        line-height: rem(16);
        letter-spacing: 0.05em;
        @include media-breakpoint-up(md) {
            font-size: $font-size-12;
        }
    }

    @supports (-ms-ime-align: auto) {
        .form-control:focus ~ .float-label,
        .form-control:valid ~ .float-label,
        .form-control:-webkit-autofill {
            top: rem(0);
            margin-bottom: rem(12);
            font-size: $font-size-3;
            color: $form-group-input-label-color;
            line-height: rem(16);

            @include media-breakpoint-up(md) {
                font-size: $font-size-12;
            }
        }
    }

    select:focus ~ .float-label,
    select:valid ~ .float-label,
    select:-webkit-autofill {
        top: rem(0);
        margin-bottom: rem(12);
        font-size: $font-size-12;
        color: $form-group-label-color;
        line-height: rem(16);
    }

    /* validation error  */
    .form-control.is-invalid {
        border-bottom: rem(0.5) solid $form-group-error-color;
        color: $form-group-error-color;
    }

    .invalid-feedback {
        margin-top: rem(8);
        margin-bottom: rem(12);
        font-size: $font-size-12;
        line-height: rem(16);
        color: $form-group-error-color;
        font-family: $font-primary;
    }

    .custom-select-wrapper {
        .custom-select {
            background: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: rem(28);
            right: 0;
            width: rem(10);
            height: rem(10);
            border-bottom: rem(1) solid $primary-black;
            border-right: rem(1) solid $primary-black;
            transform: rotate(45deg);
            transition: all 0.4s;
        }
    }

    &.required .form-control-label::before {
        content: '';
    }
}
.terms-conditions,
.tertiary-link {
    font-size: $font-size-12;
    line-height: rem(16);
}
.tertiary-link.footer-tertiary-link {
    font-size: $font-size-12;
    line-height: rem(16);
    text-underline-offset: rem(3);
}
.input-group.custom {
    .form-control {
        width: 1%;
    }
}
footer hr {
    margin-top: 0;
    margin-bottom: rem(48);
}
.footer-email-signup label[for=email-signup]::after {
    content: '*';
}
.shipping-footer-banner.row {
    margin-bottom: rem(-24);
}
/* custom amount field */
.custom-amount {
    label {
        font-family: $gotham-semibold;
        font-size: $font-size-14;
        line-height: rem(32);
        letter-spacing: 0.025em;
        margin-bottom: rem(16);
    }

    .form-control {
        width: rem(170);
        height: rem(40);
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: rem(1) solid $error-border-color;
            color: $form-group-error-color;
            background-image: none;
        }

        &::placeholder {
            font-size: $font-size-14;
        }
    }
}

/* custom textarea */
.custom-textarea {
    position: relative;
    margin-top: rem(24);

    label {
        color: $form-control-text-color;
        font-size: $font-size-14;
        font-family: $gotham-regular;
        margin-bottom: rem(8);
    }

    .custom-textarea-input {
        display: block;
        resize: none;
        border: rem(1) solid $form-control-border-color;
        border-radius: 0;
        resize: none;

        &:focus {
            border-color: $form-control-focused-border-color;
            outline: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: rem(1) solid $form-group-error-color;
            color: $form-group-error-color;
        }
    }

    .invalid-feedback {
        margin-top: rem(8);
        margin-bottom: rem(12);
        font-size: $font-size-12;
        line-height: rem(16);
        color: $form-group-error-color;
    }
}

/* custom checkbox */
.custom-checkbox {
    .custom-control-label {
        line-height: rem(24);
        font-size: $font-size-12;
        letter-spacing: 0.08em;
        font-family: $font-primary;
        font-weight: $font-weight-regular;
        color: $custom-control-text-color;

        &::before {
            border: rem(1) solid $custom-control-border-color;
            background: $custom-control-background-color;
            border-radius: 0;
            width: rem(16);
            height: rem(16);
        }

        &:hover{
            color: $custom-control-hover-text-color;
        }

        &:disabled{
            color: $custom-control-inactive-text-color;
        }
    }

    .custom-control-input {
        left: rem(-1);
        top: rem(4);
        z-index: $zindex-low;
        width: rem(18);
        height: rem(18);

        &:checked ~ .custom-control-label::before {
            color: $custom-control-text-color;
            border: rem(1) solid $custom-control-border-color;
            background-color: $custom-control-background-color;
        }

        &:checked ~ .custom-control-label::after {
            background-image: none;
            width: rem(5);
            height: rem(10);
            border: rem(1) solid $custom-control-border-color;
            transform: rotate(45deg);
            border-top: none;
            border-left: none;
            left: rem(-19);
            top: rem(6);
        }

        &:checked ~ .custom-control-label:hover {
            &::after{
                border-bottom: rem(1) solid $custom-control-hover-check-color;
                border-right: rem(1) solid $custom-control-hover-check-color;
            }
        }

        &:not(:checked):hover ~ .custom-control-label::after{
            border: none;
        }

        &:hover ~ .custom-control-label{
            color: $custom-control-hover-text-color;
            &::before {
                border: rem(1) solid $custom-control-hover-border-color;
            }
            &::after {
                border-bottom: rem(1) solid $custom-control-hover-check-color;
                border-right: rem(1) solid $custom-control-hover-check-color;
            }
        }

        &:focus ~ .custom-control-label::before {
            box-shadow: none;
            border-color: $custom-control-border-color;
        }

        &:checked ~ .custom-control-label:disabled {
            &::after{
                border-bottom: rem(1) solid $custom-control-inactive-check-color;
                border-right: rem(1) solid $custom-control-inactive-check-color;
            }
        }

        &:disabled ~ .custom-control-label{
            color: $custom-control-inactive-text-color;
            &::before {
                background-color: $custom-control-inactive-background-color;
            }
            &::after {
                border-bottom: rem(1) solid $custom-control-inactive-check-color;
                border-right: rem(1) solid $custom-control-inactive-check-color;
            }
        }

        &:not(:checked):disabled ~ .custom-control-label::before{
            border: rem(1) solid $custom-control-inactive-border-color;
            background-color: $custom-control-inactive-background-color;
        }

        &:not(:disabled):active ~ .custom-control-label::before {
            color: $custom-control-background-color;
            background-color: $custom-control-inactive-background-color;
            border-color: $custom-control-inactive-border-color;
        }

        &:checked ~ .custom-control-label:invalid {
            &::after{
                border-bottom: rem(1) solid $custom-control-error-check-color;
                border-right: rem(1) solid $custom-control-error-check-color;
            }
        }

        &:invalid ~ .custom-control-label{
            color: $custom-control-text-color;
            &::before {
                border: rem(1) solid $custom-control-error-border-color;
            }
            &::after {
                border-bottom: rem(1) solid $custom-control-error-check-color;
                border-right: rem(1) solid $custom-control-error-check-color;
            }
        }
    }
}

/* custom radio */
.custom-radio {
    position: relative;

    .form-check-label {
        padding-left: rem(24);
        font-size: $font-size-14;
        line-height: rem(20);
        font-family: $font-primary;
        color: $primary-black;
        font-weight: $font-weight-regular;
        letter-spacing: 0.05em;

        &::before {
            content: '';
            width: rem(16);
            height: rem(16);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: none;
            border: rem(1) solid $radio-color;
        }
    }

    .form-check-label:hover{
        color: $primary-grey;
        &::before {
            content: '';
            border: rem(1) solid $radio-hover-color;
        }
    }

    .form-check-label:disabled{
        color: $radio-disabled-color;
        &::before {
            content: '';
            border: rem(1) solid $radio-disabled-color;
        }
    }

    .form-check-label:invalid{
        &::before {
            content: '';
            border: rem(1) solid $radio-error-color;
        }
    }

    .form-check-label:focus{
        &::before {
            content: '';
            background: $white;
            box-shadow: 0px 0px 9px 0px $primary-grey;
            border: none;
        }
    }

    .form-check-input {
        margin-top: rem(2);
        margin-left: rem(-14);
        opacity: 0;

        &:checked ~ .form-check-label::after {
            content: '';
            width: rem(8);
            height: rem(8);
            background-color: $radio-color;
            border-radius: 50%;
            position: absolute;
            left: rem(4);
            top: rem(4);
            border: rem(1) solid $radio-color;
        }
    }

    .form-check-input:hover{
        &:checked ~ .form-check-label::after {
            content: '';
            background-color: $radio-hover-color;
            border: rem(1) solid $radio-hover-color;
        }
    }

    .form-check-input:disabled{
        &:checked ~ .form-check-label::after {
            content: '';
            background-color: $radio-disabled-color;
            border: rem(1) solid $radio-disabled-color;
        }
    }

    .form-check-input:invalid{
        &:checked ~ .form-check-label::after {
            content: '';
            background-color: $radio-error-color;
            border: rem(1) solid $radio-error-color;
        }
    }
}

/* credit card form styles */
select.expirationYear:not([value='']) ~ .float-label,
select.expirationMonth:not([value='']) ~ .float-label,
select.radius:not([value='']) ~ .float-label {
    top: rem(0);
    margin-bottom: rem(12);
    font-size: $font-size-10;
    color: $form-control-focused-color;
    line-height: rem(16);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Billing address address selector */
.address-selector-block .form-group.custom .custom-select-wrapper .custom-select.addressSelector {
    padding-bottom: rem(16);
    height: auto;
    margin-top: rem(16);
    white-space: normal;

    @include media-breakpoint-up(md) {
        border-top: 0;
        margin-top: 0;
    }

    &:disabled {
        color: $primary-black;
    }
}

.mask {
    font-family: $text-security-disc !important;
    -webkit-text-security: disc !important;
}

