@import './stripeBanner';
@import '../global/colors';

header {
    position: relative;
    background: $white;
    z-index: $zindex-highest;
    color: $primary-black;
    border-bottom: rem(0.5) solid $primary-grey;

    &.main-header {
        position: sticky;
        position: -webkit-sticky;
        top: 0;

        .m-banner-sticky & {
            top: $stripe-banner-height;
        }
    }

    .has-icon {
        display: inline-block;
    }

    .header-nav-icons-right {

        a,
        button {
            &:focus {
                outline: rem(0.5) solid $header-text-color;
            }
        }
    }

    .set-preferred-store {
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    .country-name {
        font-family: $font-medium;
        color: $primary-black;
        font-weight: $font-weight-medium;
        font-size: $font-size-12;
        line-height: rem(20);
        letter-spacing: rem(1.28);
        text-transform: uppercase;
    }
}

header .arrow,
.order-history .arrow {
    overflow: hidden;
    display: inline-block;
    font-size: $font-size-12;
    width: rem(8);
    height: rem(8);
    margin-top: -2em;
    border-top: rem(1) solid $header-text-color;
    border-left: rem(1) solid $header-text-color;

    @include media-breakpoint-down(xl) {
        border-top: rem(1) solid $primary-black;
        border-left: rem(1) solid $primary-black;
    }

    &.left {
        transform: rotate(-54deg) skew(-20deg);
    }

    &.right {
        transform: rotate(135deg) skew(-6deg);
    }
}


.browser-warning {
    position: relative;
    background-color: $alert-info-background-color;
    font-size: rem(13);
    line-height: 1.25;
    padding: rem(16);
    text-align: center;
    z-index: $zindex-low;

    a {
        text-decoration: underline;
    }
}

.header-wrapper,
.back-arrow-container {
    .search-menu {
        left: 0;
        z-index: $zindex-higher;

        @include media-breakpoint-up(lg) {
            z-index: $zindex-negative;
        }

    }
}

.back-arrow-container {
    display: flex;
    align-items: center;
    max-width: rem(1440);
    margin: auto;
}

.main-nav {
    position: relative;
    top: 0;
    padding-right: 0;
    background: transparent;
    transition: all ease-in-out 300ms;

    @include media-breakpoint-down(xl) {
        display: flex;
        padding: rem(16) 0;
    }

    @include media-breakpoint-up(jw) {
        padding-left: rem(40);
        padding-right: rem(24);
    }

    .header-nav-logo {
        margin-right: rem(28);
        display: none;

        @include media-breakpoint-up(jw) {
            display: block;
        }

        .logo {
            width: rem(130);
            height: auto;
            display: none;

            &.alt {
                display: block;
            }
        }
    }

    .header-nav-icons {

        .icons-content {
            float: none;

            @include media-breakpoint-down(xl) {
                justify-content: space-between !important; // To overide bootstrap justifying on mobile
            }
        }

        .mobile-logo {
            margin-left: rem(16);

            @include media-breakpoint-up(jw) {
                display: none;
            }
        }

        svg {
            width: rem(20);
            height: rem(20);
            color: $primary-black;
        }

        svg.lp-logo {
            width: rem(112);
            height: rem(32);
        }

        .logo {
            width: rem(130);
            height: rem(24);
            display: none;

            &.alt {
                display: block;
            }

            @include media-breakpoint-down(xl) {
                width: rem(128);
                height: rem(16);
            }
        }

        .minicart-total {
            position: relative;

            .minicart-quantity {
                font-family: $font-medium;
                font-weight: $font-weight-medium;
                font-size: $font-size-07;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -20%);
                color: $primary-black;
            }
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-left {
            @include media-breakpoint-up(jw) {
                display: none;
            }
        }

        &-right {

            a,
            button {
                &:hover {
                    stroke: $primary-black;
                }
            }
        }

        .navbar-toggler {
            padding: rem(6) rem(3);

            @include media-breakpoint-up(jw) {
                display: none;
            }

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        .user,
        .user-account-menu {
            padding: 0;
            display: none;

            @include media-breakpoint-up(jw) {
                padding: 0 rem(8);
                display: block;
            }
        }

        .search-toggle,
        .globale-switcher {
            padding: 0 rem(16) 0 0;

            @include media-breakpoint-up(jw) {
                padding: 0 rem(8);
            }
        }

        .store-locator-header-nav {
            padding: 0 0 0 rem(24);
            display: none;

            @include media-breakpoint-up(jw) {
                padding: 0 rem(8);
                display: flex;
            }
        }

        .wishlist {
            padding: 0 rem(16) 0 0;

            @include media-breakpoint-up(jw) {
                padding: 0 rem(8);
            }
        }

        .minicart {
            padding: 0;
            margin: 0;

            @include media-breakpoint-up(jw) {
                padding: 0 0 0 rem(8);
            }

            .minicart-link {
                display: flex;
            }

            .icon-tote,
            .icon-tote-active {
                display: flex;
            }
        }

        @include media-breakpoint-between(md, lg) {
            position: static;
        }
    }

    .header-nav-logo svg {
        width: rem(120);
    }

    .wishlist {
        a {
            display: block;
        }
    }

    .user-account-menu {
        position: initial;

        .dropdown-menu {
            position: absolute;
            top: 90%;
            left: 0;
            padding: rem(24);
            width: 100%;
            border: none;
            border-radius: none;

            @include media-breakpoint-only(lg) {
                padding: rem(24) rem(16) rem(32) rem(32);
                left: 75.5%;
                width: 24.5%;
            }

            @include media-breakpoint-up(xl) {
                &.non-logged-in-user {
                    padding: rem(16) rem(24);
                }
            }

            a,
            button {
                display: block;
                padding: rem(8);
                font-size: $font-size-12;
                font-family: $gotham-regular;
                background: transparent;
                color: $stripe-icon-color;
                line-height: rem(16);

                @include media-breakpoint-only(lg) {
                    padding: rem(4) 0;
                }

                &.loyalty-dashboard-link {
                    position: relative;
                    vertical-align: middle;
                }

                &.loyalty-program-drawer-link {
                    position: relative;
                    vertical-align: middle;
                    padding-bottom: 0;

                    @include media-breakpoint-only(lg) {
                        padding: 0;
                        margin-top: rem(8);
                    }
                }

                .clublilly-cta-title {
                    position: relative;
                    top: rem(5);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .user-message {
                font-family: $gotham-semibold;
                text-transform: capitalize;
                font-size: $font-size-12;
                padding-left: rem(8);
                padding-bottom: rem(8);

                @include media-breakpoint-only(lg) {
                    padding-left: 0;
                }
            }

            ul {
                flex-flow: column;
            }
        }

        .dropdown-toggle::after {
            content: none;
        }
    }
}

.club-lilly-menu-mobile {
    margin-left: rem(30);
    text-align: left;
}

.new-loyalty-badge {
    border: rem(1) solid $loyalty-new-tag-color;
    font-family: $gotham-semibold;
    padding: rem(1) rem(8);
    font-size: $font-size-12;
    line-height: rem(20);
    border-radius: rem(5);
    color: $loyalty-new-tag-color;
    position: relative;
    top: rem(-3);

    @include media-breakpoint-down(xl) {
        width: auto;
        float: right;
        padding: rem(2) rem(10);
        text-align: center;
        vertical-align: middle;
        margin: rem(10) rem(10) rem(6) 0;
        top: 0;
        margin: 0;
    }
}

.new-loyalty-badge-color {
    border: rem(1) solid $loyalty-guest-new-tag-color;
    color: $loyalty-guest-new-tag-color;
}

.header {
    padding: rem(14) 0 0;
    position: static;
    color: $header-text-color;

    @include media-breakpoint-up(jw) {
        padding: rem(14) 0;
    }

    .navbar {
        position: static;
    }

    &.main-menu {
        background-color: transparent;
        padding: 0;

        &.in {
            background-color: $header-background-color;

            .nav-item>.nav-link {
                color: $primary-black;
            }

            .menu-group {
                .navbar-nav {
                    .nav-sale>.nav-link {
                        color: $sale;
                    }
                }
            }
        }
    }

    .nav-item.show {
        position: unset;
    }

    @include media-breakpoint-down(xl) {
        .navbar-nav {
            .nav-item {
                &:nth-last-child(3) {
                    padding-top: rem(6);
                }
            }
        }
    }

    .navbar-nav .nav-item {
        &.no-bold {
            display: block;

            @include media-breakpoint-up(jw) {
                display: none;
            }
        }

        &.dropdown {
            .dropdown-container {
                .top-category {
                    .back {
                        padding-top: rem(16);

                        .arrow {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .nav-link {
            background: transparent;
            font-size: $font-size-12;
            line-height: rem(20);
            font-weight: $font-weight-medium;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            @include media-breakpoint-down(xl) {
                font-weight: $font-weight-regular;
                padding: rem(18) 0 rem(6) rem(12);
                margin: 0 rem(12);
            }

            @include media-breakpoint-up(jw) {
                padding: rem(20) rem(8);
                letter-spacing: .15em;
            }
        }

        @include media-breakpoint-up(jw) {
            &.nav-sale>.nav-link {
                color: $sale;
            }
        }

        border: none !important; // Important to override bootstrap borders
    }

    .find-store-link {
        padding-top: rem(16);
    }

    .mobile-username {
        font-family: $gotham-semibold;
        text-transform: capitalize;
        font-size: $font-size-12;
        padding: rem(12) rem(0) rem(12) rem(16);
        padding-bottom: rem(8);
        margin-top: rem(4);
    }

    svg {
        margin-right: rem(10);
        margin-top: rem(-4);
    }

    .navbar-expand-md .navbar-nav {
        @include media-breakpoint-down(xl) {
            flex-flow: column;

            .nav-link {
                padding-right: rem(16);
            }
        }
    }

    .nav-item>.nav-link {
        color: $primary-black;
        font-size: $font-size-12;
        letter-spacing: rem(0.4);
        line-height: rem(16);
        font-family: $font-primary;
        border-bottom: rem(1) solid transparent;

        &::after,
        &::before {
            content: '';
            width: 0;
            height: rem(1);
            position: absolute;
            bottom: rem(20);
            border: none;
            padding: 0 !important; //overriding bootstrap style
            background: none;
            transition: all 350ms ease-in;
            margin: 0;

            @include media-breakpoint-up(jw) {
                background: $primary-grey;
            }
        }

        &:hover,
        &:focus {
            background-color: transparent;
        }

        &::before {
            right: 42%;
        }

        &::after {
            left: 42%;
        }

        /* Safari only fix. It doesn't support transitions for pseudo selectors */
        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {

                &::before,
                &::after {
                    transition: none;
                }
            }
        }

        @include media-breakpoint-up($next-breakpoint) {

            &:hover,
            &:focus {
                outline: none;

                &::before,
                &::after {
                    width: 50%;
                }
            }
        }
    }

    .no-bold>.nav-link {
        font-family: $gotham-regular;

        svg {
            margin-right: rem(10);
        }
    }

    .dropdown {
        &-menu {
            top: 97%;

            @include media-breakpoint-down(xl) {
                padding-top: rem(0);
            }
        }

        &-container {
            position: absolute;
            left: 0;
            right: 0;
            z-index: $zindex-lowest;
            background: $header-background-color;
            margin: 0;
            display: none;

            @include media-breakpoint-up(jw) {
                display: block !important; // to override bootstrap default style for mega menu
                max-height: 0;
                padding: 0;
                padding: rem(0) rem(16);
            }

            a.dropdown-item {
                font-family: $font-medium;
            }

            .sub-menu-wrapper {
                display: flex;
                flex-flow: column;

                @include media-breakpoint-up(jw) {
                    flex-flow: row;
                    gap: rem(16);
                    margin: auto;
                    max-width: rem(1440);
                    padding: 0 rem(16);
                    width: 100%;
                }

                .dropdown-container-menu {
                    @include media-breakpoint-up(md) {
                        max-width: rem(200);
                    }

                    .dropdown-link {
                        background-color: transparent;
                        color: $stripe-icon-color;
                        font-family: $crimson;
                        padding-left: 0;
                        white-space: normal;
                        width: fit-content;

                        @include media-breakpoint-down(xl) {
                            color: $primary-black;
                        }

                        &:hover {
                            text-decoration: none;
                        }

                        span {
                            &:hover {
                                border-bottom: rem(1) solid $primary-black;
                            }

                            &:focus {
                                border-bottom: rem(1) solid $primary-black;

                                @include media-breakpoint-up(jw) {
                                    border-bottom: none;
                                }
                            }
                        }

                        &:focus-visible {
                            outline: rem(0.5) solid $primary-black;
                        }

                        &.sub-category {
                            text-underline-offset: rem(2.5);

                            &:hover {
                                text-decoration: underline;
                            }

                            &:focus {
                                text-decoration: underline;

                                @include media-breakpoint-up(jw) {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                .nav-new-Item a {
                    font-family: $font-medium;
                    font-size: $font-size-12;
                    font-weight: $font-weight-medium;
                    line-height: rem(16);
                    letter-spacing: 0.08em;
                }
            }

            a {
                color: $header-text-color;
                font-family: $gotham-regular;
                font-size: $font-size-12;
                line-height: rem(16);
            }

            .sub-cat-link {
                display: block;
                font-family: $font-medium;
                font-size: $font-size-14;
                max-width: rem(200);
                color: $primary-black;
                font-weight: $font-weight-medium;
                margin: rem(20) 0 rem(16);
                line-height: rem(16);
                padding: 0 rem(12);
                letter-spacing: 0.08em;
                text-transform: uppercase;

                &:focus {
                    outline: rem(0.5) solid $primary-black;
                }

                &:focus-visible {
                    outline: rem(0.5) solid $primary-black;
                }

                @include media-breakpoint-up(jw) {
                    margin: 0 0 rem(20);
                    padding: 0;
                    font-size: $font-size-12;
                }
            }

            .see-all-link {
                text-align: center;
                padding: rem(16);
                text-decoration: underline;
            }

            li {
                display: block;
                width: 100%;
                padding: 0.25rem 0;
                clear: both;
                min-width: rem(200);
                color: inherit;
                white-space: nowrap;
                background-color: transparent;
                border: 0;

                @include media-breakpoint-down(xl) {
                    padding: rem(4) rem(12);
                }

                img {
                    display: block;
                }
            }

            #print {
                .sub-cat-link {
                    padding-left: rem(16);
                }

                .dropdown-container-menu {
                    display: flex;
                    flex-wrap: wrap;

                    @include media-breakpoint-up(jw) {
                        max-width: rem(500);
                    }

                    li {
                        flex: 50%;
                        padding-left: rem(16);

                        @include media-breakpoint-down(xl) {
                            img {
                                width: 100%;
                            }
                        }

                        img {
                            display: block;
                        }

                        .dropdown-link {
                            font-family: $crimson;
                        }
                    }
                }
            }

            .nav-new-Item {
                padding-top: rem(12);
                text-transform: uppercase;

                @include media-breakpoint-up(jw) {
                    padding-top: 0;

                    li {
                        padding-top: 0;
                    }
                }

                .dropdown-container-menu {
                    li a.dropdown-link {
                        font-family: $font-medium;
                        font-weight: $font-weight-medium;
                        font-size: $font-size-12;
                        line-height: rem(16);
                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                    }
                }
            }

            .banner-wrapper {
                @include media-breakpoint-down(xl) {
                    order: 5;
                }

                &.m-right {
                    order: 5;
                    margin-left: auto;

                    @include media-breakpoint-down(xl) {
                        margin-left: 0;
                    }

                    .banner-card {
                        padding: 0 0 0 rem(56);

                        @include media-breakpoint-down(xl) {
                            padding: 0 rem(28);
                        }
                    }
                }
            }

            .banner-card {
                padding-right: rem(56);
                text-align: center;

                @include media-breakpoint-down(xl) {
                    display: flex;
                    flex-direction: column;
                    padding: 0 rem(28);
                }

                .banner-img {
                    margin-top: rem(36);
                    margin-bottom: rem(24);
                    order: 1;
                    padding-left: 0;

                    &:focus-visible {
                        outline: rem(0.5) solid $primary-black;
                    }

                    @include media-breakpoint-up(jw) {
                        margin-top: 0;
                    }

                    img {
                        max-width: 100%;

                        @include media-breakpoint-up(jw) {
                            max-width: initial;
                            width: rem(360);
                        }
                    }
                }

                a {
                    color: $primary-grey;
                    font-weight: $font-weight-medium;
                    letter-spacing: 0.08em;
                    line-height: rem(16);
                    order: 3;
                    text-transform: uppercase;
                    text-decoration: underline;
                    text-underline-offset: rem(2.5);

                    &:hover {
                        text-decoration: none;
                    }

                    &:focus {
                        background: transparent;
                    }

                    &.banner-img {
                        @include media-breakpoint-up(jw) {
                            margin-bottom: rem(40);
                        }
                    }
                }

                p {
                    font-family: $font-medium;
                    margin-bottom: rem(6);
                    font-size: $font-size-14;
                    line-height: rem(20);
                    font-weight: $font-weight-medium;
                    letter-spacing: 0.08em;
                    color: $primary-black;
                    order: 2;
                    text-transform: uppercase;

                    @include media-breakpoint-up(jw) {
                        font-size: $font-size-3;
                        margin-bottom: rem(14);
                    }
                }
            }

            &.is-checked {
                @include media-breakpoint-down(xl) {
                    .banner-wrapper {
                        order: 0;
                    }
                }
            }

            &.banner-position-top {
                .banner-wrapper {
                    @include media-breakpoint-down(xl) {
                        order: 0;
                    }
                }
            }
        }
    }

    .dropdown-item {

        &:hover,
        &:active {
            background-color: transparent;
        }

        &:focus {
            outline: rem(0.5) solid $primary-black;
        }

        &:focus-visible {
            outline: rem(0.5) solid $primary-black;
            color: transparent;
        }

        &.top-category {

            &:hover,
            &.active {
                @include media-breakpoint-down(xl) {
                    background: none;
                }
            }
        }
    }

    .dropdown {
        position: unset;

        &.show {
            .nav-link {

                &::before,
                &::after {
                    width: 50%;
                }

                @include media-breakpoint-down(xl) {
                    white-space: normal;
                    padding-right: rem(20);
                    color: $primary-black;
                }
            }
        }

        &.nav-sale .nav-link {
            color: $sale;
        }
    }

    .dropdown-menu {
        position: unset;
        box-shadow: none;

        &.show {
            max-height: rem(500);
            padding: rem(40) rem(16);
            z-index: $zindex-low;
        }

        @include media-breakpoint-up(jw) {
            top: 100%;
        }

        @include media-breakpoint-down(xl) {
            flex-flow: column;
        }
    }

    .navbar {
        padding: 0;
    }
}

.menu-toggleable-left .close-menu {
    display: none;
}

.navbar-toggler {
    .icon-close {
        display: none;
    }

    .icon-ham {
        display: flex;

        svg {
            width: rem(20);
            height: rem(20);
        }
    }
}

.menu-toggleable-left.in+.header-nav-icons {
    .navbar-toggler {
        .icon-close {
            display: flex;
            justify-content: center;
            align-items: center;
            height: rem(20);
            width: rem(20);

            .icon {
                height: rem(10);
                width: rem(10);
            }
        }

        .icon-ham {
            display: none;
        }
    }
}

.menu-toggleable-left.in .dropdown-menu {
    display: none;

    @include media-breakpoint-down(xl) {
        padding-bottom: rem(24);
    }
}

.menu-toggleable-left.in .show>.dropdown-menu {
    display: block;
}

/* for 200% zoom */
.menu-toggleable-left.in .show>.dropdown-menu {
    @include media-breakpoint-between(md, jw) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0;
        -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
        display: block;
    }
}

.menu-toggleable-left {
    .show>.dropdown-menu {
        overflow-x: auto;

        @include media-breakpoint-up(jw) {
            box-shadow: 0 rem(2) rem(8) $header-box-shadow-color;
            padding: rem(40) rem(64);
        }
    }

    .top-category {
        text-align: center;
        display: none;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row-reverse;
        padding: 0 rem(18);

        &:first-child {
            display: flex;
        }

        &:not(:first-child) {
            display: none !important;
        }

        .right {
            display: none;
            opacity: 0;
        }

        a {
            flex: 100%;
            text-align: center;
            font-weight: 600;
        }
    }

    @include media-breakpoint-down(xl) {
        .navbar-nav {
            .nav-sale {
                >.nav-link {
                    border-bottom: rem(1) solid $light-clay;
                    padding-bottom: rem(24);
                }
            }
        }
    }

    .nav-link:focus {
        outline: none;

        @include media-breakpoint-up(jw) {
            background: $header-focus-background-color;
        }
    }

    .nav-item .nav-link,
    .sub-cat-link,
    .dropdown-container li a {
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        line-height: rem(20);
        position: relative;
        letter-spacing: 0.05em;

        .arrow {
            position: absolute;
            right: rem(20);
            top: rem(48);

            @include media-breakpoint-up(jw) {
                display: none;
            }
        }
    }

    .menu-group {
        flex: 1 1 100%;
    }
}

.menu-toggleable-left.navbar-toggleable-md {
    top: rem(48);

    @include media-breakpoint-down(xl) {
        max-width: 80%;
        background-color: $header-background-color;

        &.in {
            left: rem(-1);
            height: 100%;
            overflow: auto;
        }
    }

    @include media-breakpoint-down(xl) {
        position: fixed;
        left: -100%;
        top: 0;
        bottom: 0;
        transition: $slide-out-animation;
        display: block;

        .m-banner-sticky .m-scrolled & {
            margin-top: $stripe-banner-height;
        }

        &.in {
            min-width: 50%;
            left: 0;
        }
    }
}

// adjusting hamburd menu postion based on banner avaivlbity and stick header
.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }
}

// adjusting hamburd menu postion based on banner, skinny banner availability and stick header
.has-skinny-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(168);
        height: calc(100% - rem(168));
    }
}

.has-banner {
    .menu-toggleable-left.navbar-toggleable-md {
        top: rem(88);
        height: 100%;
        overflow: auto;
    }

    &.has-skinny-banner {
        .menu-toggleable-left.navbar-toggleable-md {
            top: rem(208);
            height: calc(100% - rem(208));
        }
    }
}

.mobile-menu-open {
    overflow: hidden;
    height: 100%;
    width: 100%;

    .modal-background {
        display: block !important;
        touch-action: none;

        @include media-breakpoint-up(jw) {
            top: rem(50);
        }
    }
}

.skinny_banner {
    position: relative;
    z-index: $zindex-low;
}

@include media-breakpoint-down(xl) {
    .skinny_banner {
        .evg-container {
            height: rem(104);
        }
    }

    .navbar.main-nav {
        .menu-toggleable-left.navbar-toggleable-md {
            height: calc(100% - rem(66));
            top: rem(66);
        }

        &.has-skinny-banner {
            .menu-toggleable-left.navbar-toggleable-md {
                top: rem(168);
                height: calc(100% - rem(168));
            }
        }

        .main-header:not(.m-scrolled) &.has-banner {
            .menu-toggleable-left.navbar-toggleable-md {
                top: calc(rem(106) - var(--header-offset));
                height: calc(100% - rem(106) + var(--header-offset));
            }

            &.has-skinny-banner {
                .menu-toggleable-left.navbar-toggleable-md {
                    top: rem(208);
                    height: calc(100% - rem(208));
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    #girls-shop-by-size {

        .sub-cat-link,
        li {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.main-header {
    .main-nav {
        .main-menu {
            .nav-sale>.nav-link {
                cursor: pointer;
            }

            &-header {
                max-width: initial;
            }
        }
    }

    &.header-overlay {
        margin-bottom: rem(-66);
        background: $header-background-color-gradient;
        border-bottom: none;

        @include media-breakpoint-up(jw) {
            margin-bottom: rem(-77);
        }

        .main-nav {
            .header-nav-logo {
                .logo {
                    display: block;

                    &.alt {
                        display: none;
                    }
                }
            }

            .nav-item {
                >.nav-link {
                    color: $white;
                    font-size: $font-size-12;

                    &::after,
                    &::before {
                        @include media-breakpoint-up(jw) {
                            background: $primary-grey;
                        }
                    }
                }
            }

            .header-nav-icons {
                .minicart-total {
                    .minicart-quantity {
                        color: $white;
                    }
                }

                svg {
                    color: $white;
                }

                .mobile-logo {
                    .logo {
                        display: block;

                        &.alt {
                            display: none;
                        }
                    }
                }
            }
        }

        &:hover {
            @include media-breakpoint-up(lg) {
                background: $white;
            }
        }

        &.bg-white {
            background: $white;
            border-bottom: rem(0.5) solid $primary-grey;

            .main-nav {
                .header-nav-icons-right {

                    a,
                    button {
                        &:focus {
                            outline-color: $primary-black;
                        }
                    }
                }

                .main-menu {
                    .nav-sale>.nav-link {
                        color: $sale;
                    }
                }

                .header-nav-logo {
                    .logo {
                        display: none;

                        &.alt {
                            display: block;
                        }
                    }
                }

                .nav-item>.nav-link {
                    color: $primary-black;
                }

                .header-nav-icons {
                    .minicart-total {
                        .minicart-quantity {
                            color: $primary-black;
                        }
                    }

                    svg {
                        color: $primary-black;
                    }

                    .mobile-logo {
                        .logo {
                            display: none;

                            &.alt {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-nav-icons-right {

    button:focus-visible,
    a:focus-visible,
    a:active,
    button:active {
        border: rem(1) solid $primary-black;
    }
}