.autocomplete-suggestions {
    position: absolute;
    display: none;
    z-index: $zindex-medium;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
}
.autocomplete-suggestion {
    position: relative;
    background-color: $suggestion-bg-color;

    &:hover {
        text-decoration: none;
    }
}
.autocomplete-suggestion.selected {
    background-color: $suggestion-selected-bg-color;
}
