.add-product-to-wishlist-dialog,
.create-wishlist-dialog {
    &.modal {
        padding: 0 1rem;

        .generic-confirmation-header {
            margin-bottom: rem(8);

            .close {
                width: rem(24);
                color: $primary-black;
            }
        }

        .modal-dialog {
            top: 50%;
            margin: 0 auto;
            transform: translateY(-50%);
            max-width: rem(632);

            @include media-breakpoint-down(sm) {
                max-width: rem(244);
            }
        }

        .modal-content {
            border: 0;
            padding: rem(16) rem(24) rem(24);
            background-color: $wishlist-modal-bg;

            @include media-breakpoint-down(sm) {
                padding: rem(16) rem(12) rem(24);
            }

            .modal-body {
                line-height: rem(20);
                padding: rem(16) rem(84);

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }

                .add-product-wishlist-body {

                    .wishlist-modal-header {
                        height: rem(31);
                        width: rem(416);
                        margin-bottom: rem(24);
                        font-family: $font-primary;
                        font-size: rem(24);
                        line-height: rem(28);
                        letter-spacing: 0.08em;
                        font-weight: $font-weight-regular;
                        text-transform: uppercase;

                        @include media-breakpoint-down(sm) {
                            font-size: $font-size-4;
                            line-height: rem(24);
                            width: 100%;
                            margin-bottom: rem(32);
                            letter-spacing: 0.05em;
                        }
                    }

                    .default-wishlist {
                        text-transform: capitalize;
                        font-family: $font-primary;
                    }

                    .existing-list-collections {

                        .add-product-to-list {
                            .wishlist-radio-group {
                                max-height: auto;
                                overflow-y: none;
                            }
                        }

                        .custom-radio {
                            margin-bottom: rem(24);
                            font-size: $font-size-3;
                            font-family: $font-medium;
                            line-height: rem(24);
                            font-weight: $font-weight-medium;

                            label {
                                padding-left: rem(12);
                                vertical-align: top;
                                line-height: rem(24);
                                color: $primary-black;

                                &::before {
                                    top: rem(4);
                                }

                                &::after {
                                    top: rem(8);
                                    left: rem(4);
                                    width: rem(8);
                                    height: rem(8);
                                }

                                svg {
                                    width: rem(12);
                                    height: rem(12);
                                }

                                &.default-wishlist-label {
                                    .display-name {
                                        text-transform: uppercase;
                                    }
                                }

                                .display-name {
                                    font-family: $font-medium;
                                    font-size: $font-size-14;
                                    line-height: rem(20);
                                    color: $primary-black;
                                    letter-spacing: 0.08em;
                                    text-transform: uppercase;
                                    font-weight: $font-weight-medium;
                                }

                                &.create-list {
                                    .create-new-wishlist-label-button {
                                        text-transform: uppercase;
                                        color: $primary-black;

                                    }
                                }
                            }
                        }

                        .add-list-item {
                            margin-top: rem(8);
                            line-height: rem(32);
                            font-weight: $font-weight-medium;
                        }
                    }

                    .create-list-collection-plp {
                        .create-wishlist-sub-header {
                            font-size: $font-size-14;
                            font-family: $font-medium;
                            font-weight: $font-weight-medium;
                            margin-bottom: rem(24);
                        }

                        .list-name-field {
                            margin-bottom: rem(40);
                        }

                        .btn {
                            font-weight: $font-weight-medium;
                            line-height: rem(32);
                        }
                    }

                    .close-list-popup {
                        text-align: center;
                        margin-top: rem(38);
                        margin-bottom: rem(22);
                        line-height: rem(24);

                        button {
                            text-transform: uppercase;
                            background: transparent;
                            font-size: $font-size-3;
                            line-height: rem(20);
                            font-weight: $font-weight-medium;
                            font-family: $font-medium;
                            color: $primary-black;
                        }
                    }
                }
            }
        }
    }
}