@import './components/addProductToWishlistDialog';
.error-section {
    margin: rem(88) 0;

    .error-title {
        margin-top: rem(32);
        font-family: $font-primary;
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: 0;
    }

    .error-content {
        display: grid;
        justify-content: center;

        .logo-icon {
            width: rem(240);

            @include media-breakpoint-up(md) {
                width: rem(400);
            }
        }
    }

    .error-message {
        margin-bottom: rem(56);
        margin-top: rem(16);
        font-family: $font-primary;
        font-size: rem(16);
        line-height: rem(24);
        width: rem(848);
        height: rem(96);
        font-style: normal;
        font-weight: $font-weight-regular;
        font-size: $font-size-40;
        line-height: rem(48);
    }

    .continue-shopping {
        margin-top: rem(32);
        height: rem(48);
        font-family: $font-medium;
        font-size: $font-size-14;
        line-height: rem(20);
        padding: rem(12) rem(16);
        max-width: rem(416);
    }

    @include media-breakpoint-up(md) {
        margin: rem(144) 0;


        .error-title {
            margin-top: rem(48);
        }

        .error-message {
            font-size: $font-size-12;
            line-height: rem(20);
        }
    }
}

.error-page-container {
    background-color: $error-page-background;
}

.site-offline {
    .err-bg {
        width: 100%;
        height: auto;
    }

    .err-logo {
        width: rem(220);
        height: rem(28);
        margin: rem(48) 0 rem(24) 0;
    }

    .err-content {
        position: relative;
        width: 100%;
        text-align: center;
    }

    .err-title {
        font-size: $font-size-6;
        line-height: rem(40);
        font-weight: $font-weight-regular;
        letter-spacing: 0.05em;
        color: $primary-black;
        margin: 0 0 rem(16) 0;
    }

    .err-desc {
        font-size: $font-size-14;
        line-height: rem(20);
        font-weight: $font-weight-regular;
        letter-spacing: 0.05em;
        color: $primary-black;
    }

    @include media-breakpoint-up(md) {
        .err-content {
            position: absolute;
            top: 30%;
            right: 15%;
            width: rem(524);
        }

        .err-logo {
            width: rem(416);
            height: rem(52);
        }

        .err-title {
            margin: 0 0 rem(24) 0;
            font-size: $font-size-40;
            line-height: rem(48);
        }

        .err-desc {
            font-size: $font-size-3;
            line-height: rem(24);
        }
    }
}