.scrollable-container {
    position: relative;

    .scrollable-content {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        @extend .lp-scrollbar;
        @extend .no-vertical-scroll;
    }

    .scroll-left-arrow,
    .scroll-right-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        margin-top: rem(-30);
        margin-bottom: auto;
        width: rem(40);
        height: rem(40);
        background-color: $caret-scroll-bg-color;
        border-radius: 100%;
        transition: opacity 0.3s;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%);

        &.lp-icon-chevron {
            &::before {
                width: rem(8);
                height: rem(8);
            }
        }
    }

    .scroll-left-arrow {
        left: rem(30);

        &.lp-icon-chevron {
            &::before {
                left: rem(2);
            }
        }
    }

    .scroll-right-arrow {
        right: rem(30);

        &.lp-icon-chevron {
            &::before {
                right: rem(2);
            }
        }
    }
    @include media-breakpoint-up(md) {
        .scrollable-content {
            &:hover,
            &:focus {
                ~ .scroll-left-arrow,
                ~ .scroll-right-arrow {
                    opacity: 1;
                }
            }
        }

        .scroll-left-arrow,
        .scroll-right-arrow {
            &:focus,
            &:hover {
                opacity: 1;
            }
        }
    }

    .grid-tile {
        @include media-breakpoint-down(sm) {
            padding: 0 rem(4);
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}
.recommendations .scrollable-content {
    .jw-plp {
        display: none;
    }
}
