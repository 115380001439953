.search-global {
    position: relative;
    padding-bottom: rem(40);
    @include media-breakpoint-up(sm) {
        padding-bottom: 0;
    }

    .search-form {
        &-submit {
            bottom: 0;
            height: rem(24);
            padding: 0;
            position: absolute;
            left: rem(16);
            top: rem(5);
            width: rem(24);
        }

        &-clear {
            bottom: 0;
            color: $primary-black;
            height: rem(24);
            margin-right: rem(24);
            padding: 0;
            position: absolute;
            right: 0;
            text-align: -webkit-center;
            text-decoration: underline;
            text-decoration-color: $primary-grey;
            top: rem(8);
            width: rem(24);

            @include media-breakpoint-up(md) {
                margin-right: rem(16);
                text-decoration: none;
            }

            svg.svg-icon {
                height: rem(12);
                width: rem(12);
            }

            .clear-btn {
                color: $primary-grey;
                font-size: $font-size-12;
                line-height: rem(16);
                text-transform: uppercase;
            }
        }

        &-close {
            height: rem(24);
            padding: 0;
            margin-bottom: rem(16);

            svg.svg-icon {
                height: rem(12);
                width: rem(12);
            }
        }

        &-input {
            @include media-breakpoint-up(md) {
                width: 82%;
            }

            .search-field {
                border: 0;
                border-bottom: rem(1) solid $search-border-color;
                border-radius: 0;
                color: $primary-black;
                font-family: $gotham-light;
                font-size: $font-size-3;
                letter-spacing: 0.05em;
                line-height: rem(20);
                padding: rem(14) 0 rem(12) rem(50);

                @include media-breakpoint-up(md) {
                    font-size: $font-size-3;
                    line-height: rem(24);
                }
        
                &:focus {
                    border-color: $form-control-focused-border-color;
                    outline: none;
                    box-shadow: none;
                }
            }

            .search-icon {
                background-image: url("../../images/svg-icons/search.svg");
            }
        }

        .suggestions {
            border: 0;
            position: relative;
            width: auto;

            &.popular-search {
                width: calc(100% - rem(12));

                @include media-breakpoint-up(md) {
                    width: 82%;
                }
            }
        
            &-section {
                flex-direction: column;
                margin: rem(24) 0;
        
                &-title {
                    color: $primary-black;
                    font-family: $gotham-semibold;
                    font-size: $font-size-12;
                    letter-spacing: 0.08em;
                    line-height: rem(16);
                    margin-bottom: rem(12);
                    text-transform: uppercase;
        
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-14;
                        line-height: rem(20);
                        margin-bottom: rem(16);
                    }
                }
        
                &-items {
                    color: $primary-black;
                    font-family: $gotham-regular;
                    font-size: $font-size-14;
                    line-height: rem(20);
                    letter-spacing: 0.05em;
                    margin-bottom: rem(4);

                    @include media-breakpoint-up(md) {
                        margin-bottom: rem(8);
                    }

                    a {
                        color: $primary-black;
                    }
                }
            }

        
            &-related-products {        
                .col-3 {
                    padding-left: rem(4);
                    padding-right: rem(4);
                }
        
                a {
                    span {
                        display: block;
                    }
                }
            }
        
            &-category-links {
                letter-spacing: 0.025em;
                display: table;
            }
        
            .category-wrap {
                justify-content: start;

                @include media-breakpoint-up(md) {
                    margin-left: rem(-8);
                    padding: 0;
                }
            }
        
            .category-wrap.category-wrap-mobile {
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
        
                    .suggestions-section {
                        padding-bottom: rem(16);
                    }
                }
            }
        
            .product-tile .tile-body {
                padding-bottom: rem(8);
                padding-top: 0;
            }

            .item {
                padding-bottom: 0;
            }
        }

        .suggestions-wrapper {
            .suggestions {
                .left-container {
                    @include media-breakpoint-up(md) {
                        padding-right: 0;
                    }
                }

                &-section {
                    &.suggestions-related-products {
                        margin-bottom: 0;
                        width: inherit;
                        
                        .suggestions-section-title {
                            color: $primary-black;
                            font-family: $font-medium;
                            font-size: $font-size-14;
                            font-weight: $font-weight-medium;
                            letter-spacing: 0.08em;
                            line-height: rem(20);
                            margin: rem(16) 0;
                        }

                        .suggestions-section-items {
                            & > .row {
                                margin: 0;
                            }

                            .product-tile {
                                padding: 0 rem(16) 0 0;
                                height: auto;
                                @include media-breakpoint-up(md) {
                                    min-height: rem(400);
                                }

                                .pdp-link {
                                    margin-bottom: 0;

                                    span.product-name {
                                        margin: rem(4) auto;
                                    }
                                    .link {
                                        margin-left: rem(1);
                                       &:focus, &:hover {
                                        outline: none;

                                        img {
                                            outline: rem(0.75) solid $primary-black;
                                        }
                                       }
                                        
                                        img {
                                            margin-top: rem(1);
                                            margin-bottom:rem(4);
                                            height: auto;
                                        }
                                    }
                                }

                                .tile-body {
                                    .price {
                                        color: $primary-black;
                                        font-family: $font-medium;
                                        font-size: $font-size-14;
                                        font-weight: $font-weight-medium;
                                        letter-spacing: 0.08em;
                                        line-height: rem(20);

                                        .price-section {
                                            flex-direction: column;
                                            
                                        }
                                        del:not(.d-none)+.sales {
                                            margin-left: 0;
                                            color: $sale;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &-search-str {
                    letter-spacing: 0.025em;
        
                    .item {
                        padding-bottom: 0;
                    }
                }

                &-search-str ~ .category-wrap-mobile {
                    .suggestions-section {
                        margin-top: 0;
                    }
                }
            }
        }        
    }

}

.search-menu {
    position: absolute;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s;
    z-index: $zindex-lowest;
    top: 0;

    @include media-breakpoint-up(lg) {
        top: unset;
    }

    &-container {
        padding-top: rem(16);
        padding-bottom: 100%;
        background-color: $search-menu-background-color;
        height: 100vh;

        @include media-breakpoint-up(md) {
            height: auto;
            padding: rem(16) rem(64) rem(64);
        }

        &-scrollable {
            max-height: calc(100vh - rem(80));
            max-width: rem(1280);
            overflow-y: auto;
            overflow-x: hidden;

            .col-sm-12 {
                @include media-breakpoint-down(md) {
                    padding: 0 rem(12);
                }
            }
        }

        .container {
            max-width: 100%;
        }
    }

    .fade-bg {
        top: unset;
        height: 100vh;
        position: relative;
    }

    &.header-search-type {
        max-height: 0;

        .search-global {
            display: none;
        }
    }

    &.open {
        max-height: 100vh;
        display: table;
        width: 100%;

        .search-global {
            display: block;
        }
    }
}

.suggestions-section-items .item.product-tile {
    @include media-breakpoint-down(sm) {
        padding: 0 rem(4);
    }
}

.row.suggestions-section.suggestions-related-products {
    @include media-breakpoint-down(sm) {
        padding-bottom: rem(40);
    }
}

.plp-style {
    z-index: $zindex-lowest;

    .suggestions-wrapper {
        box-shadow: 0 rem(10) rem(15) 0 rgba(0, 0, 0, 0.12);
        position: absolute;
        width: 100%;
    }

    .suggestions-related-products {
        display: none;
    }

    .suggestions {
        padding: 0 rem(40) rem(24);

        .left-container,
        .right-container {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}

.suggestions-section-items {
    .item.term.search-suggestions-item,
    .item.category-suggestions-name {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
