@import '../recommendations';

.not-found-container {
    margin-top: rem(72);
    margin-bottom: rem(72);
    padding: 0 rem(12);
    max-width: 100%;

    @include media-breakpoint-up(md) {
        padding: 0 rem(80);
    }

    .text-center {
        .error-message {
            font-size: rem(24);
            font-family: $font-primary;
            line-height: rem(32);
            max-width: rem(320);
            margin: 0 auto rem(40);

            @include media-breakpoint-up(md) {
                font-size: rem(40);
                line-height: rem(48);
                margin-bottom: rem(56);
                max-width: rem(600);
            }
        }
    }

    .error-info {
        margin-bottom: rem(32);
        letter-spacing: rem(0.3);
        font-family: $font-primary;
    }
    .container {
        &.recommendations {
            margin-top: rem(60);
            margin-bottom: rem(50);
            padding: 0;
            @include media-breakpoint-up(md) {
                margin-top: rem(104);
                margin-bottom: rem(56);
            }
            .product-listing-header {
                margin: 0;
                padding: 0;
                .title {
                    margin-bottom: rem(32);
                    font-size: $font-size-3;
                    line-height: rem(20);
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-4;
                        line-height: rem(24);
                        margin-bottom: rem(56);
                    }
                }
            }
            .grid-tile {
                flex: 0 0 70%;
                max-width: 70%;
                padding: 0 rem(6);
                @include media-breakpoint-up(md) {
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding: 0 rem(8);
                }
            }
            .scroll-bar {
                padding-bottom: rem(40);
                @include media-breakpoint-up(md) {
                    padding-bottom: rem(48);
                }
            }
        }
    }

    .product-listing-button {
        display: none;
    }

    .error {
        &.continue-shopping {
            max-width: rem(220);
            margin: 0 auto;
            line-height: rem(20);
            font-family: $font-medium;
            padding: rem(12) rem(16);
            @include media-breakpoint-up(md) {
                max-width: rem(416);
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(56);
        margin-bottom: rem(104);

        .error-message {
            font-size: rem(32);
            line-height: rem(40);
        }


    }
}

.order-detail-error {
    line-height: rem(20);
    width: rem(293);
    margin-left: auto;
    margin-right: auto;
}

.modal-background {
    z-index: $zindex-lowest;
}

.rendering-template {
    display: inline-block;
}

.padding-12 {
    padding: 0 rem(12);
}
