@import 'productCard';

.minicart-link {
    -webkit-appearance: none;
    padding: 0;
}

#minicart {
    .modal-header,
    .modal-body {
        margin: 0;
        padding: 0 rem(12);

        @include media-breakpoint-up(md) {
            padding: 0 rem(48);
        }
    }

    .modal-body {
        height: calc(100% - rem(124));

        @include media-breakpoint-up(md) {
            height: calc(100% - rem(140));
        }
    }

    @include media-breakpoint-up(xl) {
        .modal-dialog {
            width: 34.5%;
            right: -34.5%;
        }

        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }

    #minicart-label {
        font-size: $font-size-4;
        font-weight: $font-weight-regular;
        letter-spacing: 0.05em;
        margin-bottom: rem(40);
        padding: 0;
        margin-top: rem(56);

        @include media-breakpoint-up(md) {
            font-size: $font-size-5;
            line-height: rem(28);
            letter-spacing: 0.08em;
            margin-bottom: rem(48);
            margin-top: rem(64);
        }
    }

    .store-cross {
        text-align: end;
        position: absolute;
        right: rem(16);
        top: rem(24);
        line-height: rem(16);
        margin: 0;
        z-index: 1;

        @include media-breakpoint-up(md) {
            right: rem(24);
            top: rem(24);
        }

        .svg-icon {
            height: rem(16);
            width: rem(16);
        }
    }

    .button-primary.btn-block {
        font-family: $font-medium;
        line-height: rem(20);
        padding: rem(12) 0;
        position: static;
        margin: 0 auto;
        font-size: $font-size-14;
    }

    @include media-breakpoint-up(md) {
        // .modal-content height and .modal-header vertical margins must be calculated together
        // reducing margin in the header leads to the content not vertically filling the viewport.
        $minicart-header-adjustment-px: 8;

        .modal-content {
            height: calc(100% + rem($minicart-header-adjustment-px));
        }
    }

    .cart .gwp-container {
        border: none;
        padding-bottom: 0;
    }

    .minicart-footer {
        border: none;

        .estimated-total {
            margin: 0;
        }
    }

    .cart-empty {
        display: none;
    }

    .empty-cart-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .content-asset {
            flex: 1;
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .cart {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;

        .item-image {
            border: rem(1) solid transparent;
            align-self: flex-start;
            width: auto;

            &:focus {
                outline: 0;
                border-color: $primary-black;
            }
        }
    }

    .card {
        border: none;
        width: 100%;
        margin-bottom: rem(24);
    }

    .card-body {
        width: 100%;
        border: none;
        padding: 0;
    }

    .remove-line-item {
        margin-top: rem(16);

        &>.remove-btn {
            color: $primary-grey;
            font-family: $font-medium;
            font-size: $font-size-12;
            font-weight: $font-weight-medium;
            letter-spacing: 0.08em;
            line-height: rem(16);
            text-decoration: underline;
            text-transform: uppercase;
        }
    }

    .sub-total-with-value {
        color: $primary-black;
        font-family: $font-medium;
        font-size: $font-size-14;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: $font-size-4;
        margin: rem(16) auto rem(32);
        text-transform: uppercase;
    }

    .approaching-discounts {
        margin-top: 0;
        text-align: center;
    }
}

.minicart-error {
    width: 100%;
    padding: 0 rem(26) 0 rem(14);

    @include media-breakpoint-up(md) {
        padding: 0 rem(65) 0 rem(55);
    }

    .cart-error-message {
        text-align: left;
        margin-top: 0;
        margin-bottom: rem(24);
        padding: rem(16) 0 0 0;

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }
}

.footer-divider {
    margin: 0;
}

.mini-cart-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin: 0 rem(-4);

    @include media-breakpoint-up(md) {
        margin: 0 rem(-40);
    }

    .price-display {
        flex-direction: column;
        align-items: flex-end !important; //To override OOTB code

        .total-price-nocoupon{
            color: $sale;
            margin-top: 0;
        }

        .pricing{
            flex-direction: column;

            .markdownOriginal{
                color: $sale;
            }
        }
        .single-price-mobile{
            display: flex;
        }
    }

    .coupon-applied-price{
        font-size: $font-size-12;
        margin-top: 0;
        color: $sale;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(8);
        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-bottom: rem(8);
        }
        .promo-call-out-msg{
            margin-right: rem(2);
        }
        .has-been-applied{
            margin-right: rem(4);
        }

    }
    .single-price-display{
        display: none;
    }

    .product-promo-price{
        .initial-price-display {
            display: none;
        }
    }

    .display-promotion-details{
        .line-item-total-price-amount {
            margin-bottom: rem(4);
            font-family: $font-primary;

            .total-label {
                min-width: rem(48);
                color: $primary-black;
                font-size: $font-size-12;
                font-family: $font-primary;
                font-weight: $font-weight-regular;
            }
            .total-price-display {
                .markdownTotal {
                    color: $primary-black;
                    font-size: $font-size-12;
                    font-family: $font-primary;
                    font-weight: $font-weight-regular;
                    margin-left: 0;
                    margin-right: rem(4);
                    padding-left: rem(4);
                }
                .final-sale-price {
                    padding-left: rem(4);
                    color: $sale;
                    font-size: $font-size-12;
                    font-family: $font-primary;
                    font-weight: $font-weight-regular;
                    margin-left: 0;
                }
            }
        }
    }

    .final-sale-message {
        margin-bottom: 0;
    }

    .line-item-header {

        div,
        p {
            &.line-item-name {
                font-size: $font-size-14;
                font-family: $font-medium;
                font-weight: $font-weight-medium;
                line-height: $font-size-4;
                letter-spacing: 0.08em;
                margin-bottom: rem(16);
                text-transform: uppercase;
                white-space: normal;
                padding-right: rem(24);
                padding-left: rem(1);

                & a {
                    color: $primary-black;
                }
            }
        }

        ~.line-item-attributes {
            font-size: $font-size-12;
            line-height: rem(16);
            margin-top: 0;

            .color-value {
                padding-left: rem(4);
            }
        }
    }

    .line-item-total-price-amount {
        color: $primary-black;
        font-family: $font-medium;
        font-size: $font-size-12;
        font-weight: $font-weight-medium;
        line-height: rem(16);
        letter-spacing: 0.05em;
    }

    .quantity-value {
        .line-item-attributes {
            color: $primary-black;
            font-size: $font-size-12;
            margin-bottom: rem(16);
        }
    }

    .cart {
        flex-direction: column;
        height: 100%;

        .gwp-container {
            .gwp-title {
                border: none;
                margin: rem(32) 0 rem(16);
                padding: 0;
            }

            .product-line-item-details {
                margin-bottom: rem(32);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .mini-cart-scrollable-products {
            flex: 1;
            padding: 0 rem(4) 0 rem(4);
            width: 100%;
            overflow-x: hidden;
            scrollbar-gutter: stable;

            @include media-breakpoint-up(md) {
                padding: 0 rem(40);
            }
        }

        .mini-cart-line-items {
            padding-bottom: rem(24);

            .mini-cart-store-title {
                border: none;
                padding: rem(24) 0;
                margin: 0 0 rem(8);
            }
        }

        .sticky-mini-cart-footer {
            width: 100%;
            padding: 0 rem(8);

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            .btn-block {
                @include media-breakpoint-up(md) {
                    width: 100% !important;
                }
            }

            .review-btn-section {
                padding: 0;

                @include media-breakpoint-up(md) {
                    padding: 0 rem(72);
                }
            }
        }
    }
}

.minicart-recommendation-container {
    .minicart-product-recommendation {
        .product-recommendation-header {
            display: flex;
            justify-content: space-between;

            .title {
                padding: 0;
                margin: rem(14) 0 rem(18) rem(12);
                font-family: $font-medium;
                font-weight: $font-weight-medium;
                font-size: $font-size-14;
                line-height: rem(20);
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: $primary-black;

                @include media-breakpoint-up(md) {
                    margin: rem(18) 0 rem(18) rem(16);
                }
            }

            .close {
                margin: rem(12) rem(12) 0 0;
                opacity: 1;

                @include media-breakpoint-up(md) {
                    margin: rem(16) rem(16) 0 0;
                }

                &:hover {
                    opacity: 0.75;
                }
            }

            .close-icon {
                position: relative;
                display: inline-block;
                width: rem(24);
                height: rem(24);
                margin-bottom: rem(4);
                cursor: pointer;

                &:before,
                &:after {
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    display: inline-block;
                    content: ' ';
                    height: rem(16);
                    width: rem(0.5);
                    background-color: $primary-black;
                    cursor: pointer;
                    top: 18%;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        .product-recommendation-body {
            padding: 0 rem(12);

            @include media-breakpoint-up(md) {
                padding: 0 rem(16);
            }

            .product-recommendation-tile {
                .product-body {
                    padding: 0 0 0 rem(12);

                    @include media-breakpoint-up(md) {
                        padding: 0 0 0 rem(16);
                    }

                    .product-desc {
                        padding: 0;
                        max-width: 70%;

                        .product-name {
                            margin-bottom: rem(16);
                            font-family: $font-medium;
                            font-weight: $font-weight-medium;
                            font-size: $font-size-12;
                            line-height: rem(16);
                            letter-spacing: 0.08em;
                            text-transform: uppercase;
                            color: $primary-black;
                            float: initial;
                            border-bottom: none;
                        }

                        .line-item-attributes:not(:first-of-type) {
                            margin-top: rem(8);
                        }

                        .attribute-name {
                            padding-right: rem(4);
                            font-family: $font-primary;
                            font-weight: $font-weight-regular;
                            font-size: $font-size-12;
                            line-height: rem(16);
                            letter-spacing: 0.05em;
                            color: $primary-black;
                        }

                        .attribute-value {
                            font-family: $font-primary;
                            font-weight: $font-weight-regular;
                            font-size: $font-size-12;
                            line-height: rem(16);
                            letter-spacing: 0.05em;
                            color: $primary-black;
                        }

                        .full-details {
                            margin-top: rem(16);

                            .see-full-details {
                                font-family: $font-medium;
                                font-size: $font-size-12;
                                font-weight: $font-weight-medium;
                                line-height: rem(16);
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $primary-grey;
                                border-bottom: rem(1) solid $primary-grey;
                                text-decoration: none;
                            }
                        }
                    }

                    .price {
                        margin-bottom: rem(16);
                        padding: 0;
                        font-family: $font-medium;
                        font-weight: $font-weight-medium;
                        font-size: $font-size-12;
                        line-height: rem(16);
                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                        color: $primary-black;
                        text-align: right;

                        .price-section {
                            flex-direction: column;
                        }

                        .sales.item-adjusted {
                            color: $sale;
                            margin-left: 0;
                        }

                    }
                }

                .tile-footer {
                    margin-left: rem(12);
                    margin-top: rem(16);

                    @include media-breakpoint-up(md) {
                        margin-left: rem(16);
                        margin-top: rem(24);
                    }

                    .cart-and-ipay {
                        padding-bottom: rem(12);

                        @include media-breakpoint-up(md) {
                            padding-bottom: rem(19);
                        }
                    }

                    .add-to-cart.button-primary.btn-block {
                        border: rem(1) solid $primary-black;
                        font-family: $font-medium;
                        font-size: $font-size-14;
                        font-weight: $font-weight-medium;
                        line-height: rem(20);
                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                        background-color: $white;
                        color: $primary-black;
                        position: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mini-cart-promos {
    font-family: $font-primary;
    margin: rem(24) rem(-16) auto;

    @include media-breakpoint-up(md) {
        margin: rem(24) rem(-8) auto;
    }

    .minicart-banner-inner {
        background: $primary-grey;
        height: auto;
        padding: rem(22) 0 rem(14);

        @include media-breakpoint-up(md) {
            padding: rem(20) 0;
        }

        h3 a {
            color: $white;
            font-family: $font-primary;
            font-size: $font-size-10;
            letter-spacing: 0.05em;
            line-height: rem(12);

            @include media-breakpoint-up(md) {
                font-size: $font-size-12;
                letter-spacing: 0.08em;
                line-height: rem(16);
            }
        }
    }
}

.gwp-title {
    font-family: $gotham-bold;
    font-size: $font-size-3;
    line-height: rem(24);
    letter-spacing: 0.063em;
    margin-top: rem(80);
    padding-bottom: rem(8);
    border-bottom: rem(1) solid $gwp-title-border-color;
    margin-bottom: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        border-bottom: none;
        margin-top: rem(40);
        padding-bottom: 0;
        border-top: rem(1) solid $gwp-title-border-color;
        padding-top: rem(24);
    }
}

.cart-error-message {
    color: $cart-error;
    font-family: $gotham-regular;
    font-size: $font-size-12;
    letter-spacing: 0.025em;
    line-height: rem(16);
    margin-top: rem(16);
    margin-bottom: 0;
    text-align: center;
    padding: 0 rem(32);

    @include media-breakpoint-up(md) {
        margin-top: rem(24);
        margin-bottom: rem(8);
        padding: 0;
    }
}

.out-of-stock-img {
    filter: grayscale(1);
}

.price {
    div[class*='item-adjusted'] {
        color: $sale;
    }

    .pricing:not(.sale-price,.total-price-nocoupon) {
        color: $primary-black !important;
    }
}

.mini-cart-container {
    .item-attributes {
        margin: 0 0 0 rem(12);

        @include media-breakpoint-up(md) {
            margin: 0 0 0 rem(16);
        }

        .line-item-attributes {
            color: $primary-black;
            margin-bottom: rem(4);
        }

        .availability-message {
            .availability-message-text {
                color: $availability-error-message-color;
            }
        }

        .attribute-name,
        .attribute-value {
            letter-spacing: 0.05em;
            line-height: rem(16);
        }

        .attribute-name {
            min-width: rem(48);
        }

        .attribute-value {
            padding-left: rem(4);
        }
    }

    .free-text {
        color: $product-total-price-color;
        font-family: $gotham-semibold;
        font-weight: normal;
        font-size: $font-size-14;
        line-height: $font-size-4;
    }

    .product-line-item-details {
        .price {
            padding-left: rem(14);
            padding-right: rem(12);

            @include media-breakpoint-up(md) {
                padding-right: rem(16);
            }
        }

        .coupon-description {
            display: none;
        }

        .product-image {
            height: rem(138);
            width: rem(92);
        }
    }

    .minicart-product-recommendation {
        border: rem(0.5) solid $primary-grey;
        margin-bottom: rem(24);

        .product-recommendation-tile {
            .product-image {
                width: rem(92);
            }

            .tile-body {
                width: calc(100% - rem(92))
            }
        }
    }

    .gwp-container {
        p.line-item-name {
            font-family: $gotham-regular;
        }

        .item-message {
            margin: 0;
            font-size: $font-size-12;
        }
    }
}

.coupon-description {
    font-family: $gotham-regular;
    font-size: $font-size-12;
    letter-spacing: 0.025em;
    line-height: rem(16);
    color: $sale;
}

.info-messages {
    .line-item-availability {
        margin-top: rem(8);

        .line-item-attributes {
            line-height: rem(16);
            margin-top: 0;
            margin-right: 0;

            .similar-styles-category-link {
                color: $link-color;
                text-decoration: underline;
                letter-spacing: 0.08em;
                display: block;
                margin-top: rem(8);
                padding-left: 0;
                @include media-breakpoint-up(lg) {
                    display: inline-flex;
                    padding-left: rem(4);
                }
            }
        }
        .restricted-product {
            .similar-styles-category-link {
                @include media-breakpoint-up(lg) {
                    display: block;
                    padding-left: 0;
                }
            }
        }
    }
    .final-sale-message {
        margin-top: rem(8);
        padding-right: 0;
    }
}

.product-line-item-details {
    .cart-body-items {
        width: calc(100% - rem(92));

        .body-align {
            padding: 0;
        }
    }
}
