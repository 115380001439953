$sidenav-width-xs: 100%;
$sidenav-width-md: 60%;
$sidenav-width-lg: 40%;
$sidenav-width-xl: 33%;
$overlay-opacity: 0.2;

.lp-scrollbar {
    overflow: auto;

    &::-webkit-scrollbar-button {
        display: none;
        height: rem(12);
        border-radius: 0;
        background-color: $silver;
    }

    &::-webkit-scrollbar-button:hover {
        background-color: $primary-grey;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-grey;
        border-radius: rem(4);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $primary-grey;
    }

    &::-webkit-scrollbar-track {
        background-color: $wildsand;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: $wildsand;
    }

    &::-webkit-scrollbar {
        width: rem(4);
        height: rem(4);
    }

    scrollbar-color: $primary-grey transparent;
    scrollbar-width: thin;

    &.m-scrollbar-hidden {
        scrollbar-color: transparent;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

/* Safari displays vertical & horizontal scroll bar for overflow:auto style */
.no-vertical-scroll {
    overflow-y: hidden;
}

// background opacity styles
.fade-bg {
    opacity: 0;
    position: fixed;
    z-index: $zindex-lowest;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background-color: rgba($black, $overlay-opacity);
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.open {
    .fade-bg {
        opacity: 1;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s;
    }
}

// LP Cross Icon in CSS
// - use class name for 'lp-icon-plus', 'lp-icon-cross'
// - add icon-w24 class name to change size 'lp-icon-plus icon-w24', 'lp-icon-cross icon-w24'
.lp-icon {

    &-plus,
    &-cross {
        display: block;
        position: relative;
        height: rem(16);
        width: rem(16);

        &::before,
        &::after {
            content: ' ';
            position: absolute;
            height: rem(16);
            width: rem(1);
            background-color: $stripe-icon-color;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: rgba($stripe-icon-color, 0.8);
            }
        }

        &.icon-w24 {
            display: block;
            position: relative;
            height: rem(24);
            width: rem(24);

            &::before,
            &::after {
                height: rem(24);
            }
        }
    }

    &-plus {
        &::before {
            transform: rotate(0);
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    &-cross {
        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &-chevron {

        &::before,
        &.double::after {
            content: '';
            position: relative;
            color: $stripe-icon-color;
            border-style: solid;
            border-width: rem(1) rem(1) 0 0;
            height: rem(16);
            width: rem(16);
            transform: rotate(-45deg);
            display: inline-block;
        }

        &.double::after {
            margin-left: rem(-3);
        }

        &.chevron-left {

            &::before,
            &.double::after {
                transform: rotate(-135deg);
            }
        }

        &.chevron-right {

            &::before,
            &.double::after {
                transform: rotate(45deg);
            }
        }
    }

    &-caret {
        &::after {
            position: absolute;
            content: '';
            transform: rotate(45deg);
            height: rem(12);
            width: rem(12);
            border-bottom: rem(1) solid $stripe-icon-color;
            border-right: rem(1) solid $stripe-icon-color;
            right: 0;
            transition: all 0.3s ease;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &[aria-expanded='true'] {
            &::after {
                transform: rotate(225deg);
                top: rem(9);
            }
        }
    }
}

// slideout menu styles & transition
.lp-slideout {
    &-menu {
        position: fixed;
        top: 0;
        height: 100%;
        width: $sidenav-width-xs;
        right: -$sidenav-width-xs;
        transition: all 0.5s;
        overflow: auto;
        z-index: $zindex-low;

        @include media-breakpoint-up(md) {
            width: $sidenav-width-md;
            right: -$sidenav-width-md;
        }

        @include media-breakpoint-up(lg) {
            width: $sidenav-width-lg;
            right: -$sidenav-width-lg;
        }

        @include media-breakpoint-up(xl) {
            width: $sidenav-width-xl;
            right: -$sidenav-width-xl;
        }
    }

    &.active {
        .lp-slideout-menu {
            right: 0;
        }
    }

    &-open {
        .fade-bg {
            opacity: 1;
            width: 100%;
            height: 100%;
            transition: opacity 0.5s;
        }
    }
}

/*******************************
* MODAL AS RIGHT SIDEBAR
* Add "lp-slideout-modal" in modal parent div, ex: class="lp-slideout-modal modal fade"
*******************************/
.lp-slideout-modal.modal {
    .modal-header {
        border: 0;
        margin: rem(16);
        padding: 0;
        display: block;
        position: relative;

        @include media-breakpoint-up(md) {
            margin: rem(40);
            padding: 0;
        }

        h4 {
            font-family: $font-primary;
            font-size: rem(24);
            line-height: rem(28);
            letter-spacing: rem(0.8);
            text-transform: uppercase;
            padding-top: rem(30);
        }

        .close {
            position: absolute;
            margin: 0;
            top: 0;
            right: 0;
            padding: 0;
        }
    }

    .modal-body {
        margin-bottom: rem(160);
        padding: 0 rem(12);

        @include media-breakpoint-up(md) {
            padding: 0 rem(48);
        }

        h4 {
            font-family: $font-primary;
            font-size: rem(16);
            line-height: rem(24);
            letter-spacing: rem(1);
            text-transform: uppercase;
            padding-bottom: 1rem;
        }
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        transform: translate3d(0%, 0, 0);
        height: 100%;
        transition: all 0.3s;
        width: $sidenav-width-xs;
        right: -$sidenav-width-xs;
        box-shadow: 0 0 rem(16) rgba(0, 0, 0, 0.15);
        max-width: rem(500);

        @include media-breakpoint-up(md) {
            width: $sidenav-width-md;
            right: -$sidenav-width-md;
        }

        @include media-breakpoint-up(lg) {
            width: $sidenav-width-lg;
            right: -$sidenav-width-lg;
        }

        @include media-breakpoint-up(xl) {
            width: $sidenav-width-xl;
            right: -$sidenav-width-xl;
        }
    }

    .modal-content {
        font-family: $font-primary;
        color: $primary-black;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: rem(0);
        border: none;
    }

    &.fade {
        opacity: 1;
    }

    &.show {
        // transition: opacity 0.3s, right 0.3s;
        opacity: 1;

        .modal-dialog {
            right: 0;
        }
    }
}

.modal-backdrop {
    width: 0;
    height: 0;
    transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    z-index: $zindex-highest;

    &.show {
        width: 100%;
        height: 100%;
        opacity: $overlay-opacity;
        transition: opacity 0.3s;
    }
}

.custom-info-icon {
    width: rem(16);
    height: rem(16);
    border: rem(0.5) solid $info-icon-border;
    border-radius: 50%;
    text-align: center;
    line-height: rem(16);
    font-size: $font-size-12;
    padding: 0;
    display: inline-block;
    cursor: pointer;

    &:hover {
        background-color: $active-info-icon-bg;
        color: $white;
    }

    &:focus {
        background-color: $active-info-icon-bg;
        color: $white;
    }
}

/*******************************
* Generic Modal
*******************************/
.generic-modal-dialog {
    .modal-dialog {
        padding: 0 rem(8);
        margin-top: rem(100);
    }

    .modal-content {
        padding: rem(32);
    }

    .generic-confirmation-body {
        @include media-breakpoint-up(md) {
            margin: 0 rem(32) rem(40);
        }

        margin-bottom: rem(24);
        text-align: center;
        padding: 0;
        font-family: $gotham-semibold;
        font-size: rem(16);
        line-height: rem(24);
        color: $base-text-color;
    }

    .generic-confirmation-body-body {
        margin-bottom: rem(24);
        text-align: center;
        font-family: $gotham-regular;
        font-size: rem(14);
        line-height: rem(20);
        color: $base-text-color;
    }

    .generic-confirmation-body-heading {
        margin-bottom: 0;
        text-align: center;
        padding: 0;
        font-family: $gotham-semibold;
        font-size: rem(16);
        line-height: rem(24);
        color: $link-color;
    }

    .generic-confirmation-header {
        .close {
            top: rem(24);
            right: rem(32);
        }
    }

    .modal-footer {
        border: none;
    }

    &.modal.fade .modal-dialog {
        margin: 0 auto;
        transform: translateY(-50%);
        top: 50%;
    }
}

/*******************************
* Back arrow icon
*******************************/
.back-arrow {
    font-size: $font-size-12;
    line-height: rem(16);
    font-family: $font-primary;
    margin-top: rem(16);
    color: $base-text-color;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding-left: rem(16);
    letter-spacing: 0.05em;

    &::after {
        width: rem(12);
        height: rem(12);
        content: '';
        border-top: rem(1) solid $base-border-color;
        border-left: rem(1) solid $base-border-color;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: rem(2);
    }
}

/* custom error alert in form validations*/
.custom-error-alert {
    color: $error-text-color;
    font-size: rem(12);
    line-height: rem(16);
    padding: rem(16) 0;
    font-family: $gotham-regular;
    letter-spacing: rem(0.4);
    font-weight: normal;
}

/*******************************
* Alert styles
*******************************/
.alert-info {
    background-color: $alert-info-background-color;
    border-color: $alert-info-border-color;
    color: $alert-info-text-color;
}

/*chevron left/right focus state change*/
.carousel-control-next,
.carousel-control-prev {

    &:focus,
    &:hover {
        .lp-icon-chevron {

            &::after,
            &::before {
                border-width: rem(2) rem(2) 0 0;
            }
        }
    }
}

.alert-dismissible .close {
    padding: rem(8);
}