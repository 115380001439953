.video-template {
    margin-bottom: rem(104);

    .video-headings {
        height: rem(112);
    }

    .title,
    .subtitle {
        text-align: center;
        color: $video-component-title-color;
    }

    .title {
        h1, h2, h3, h4, h5, h6 {
            font-family: $gotham-semibold;
            font-size: rem(24);
            line-height: rem(32);
            letter-spacing: rem(2);
        }
    }

    .subtitle {
        font-family: $crimson;
        font-size: rem(18);
        line-height: rem(24);
        letter-spacing: rem(0.6);
        margin-top: rem(16);
    }
}

.video-component {
    position: relative;
    display: block;
    justify-content: center;

    video {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .play-video {
        display: none;
        position: absolute;
        top: calc(50% - 2.5rem);
        left: 50%;
        transform: translateX(-50%);
        width: rem(80);
        height: rem(80);
        padding: 0;

        svg {
            width: rem(80);
            height: rem(80);
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    // hiding play-video button while video playing
    .play-video.playing {
        display: none !important; //PDP video has d-block which is block !important so playing needs to have none !important
    }
}
