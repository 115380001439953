.back-to-top-icon {
    position: fixed;
    bottom: rem(28);
    right: rem(80);
    z-index: $zindex-low;
    padding: 0;
    width: rem(48);
    height: rem(48);

    svg {
        background-color: $back-to-top-bg-color;
        border: rem(0.5) solid $primary-black;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        padding: rem(14);
    }

    @include media-breakpoint-down(sm) {
        bottom: rem(12);
        right: rem(6);

        svg {
            width: rem(34);
            height: rem(34);
            padding: rem(9);
        }
    }
}