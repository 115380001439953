.cookie-preferences,
.onetrust-do-not-sell-link,
.onetrust-privacy-link {
    padding: 0;
}

#onetrust-privacy-dialog {
    .modal-dialog {
        min-width: rem(800);
        max-width: 80%;
        width: 100%;

        @include media-breakpoint-down(md) {
            min-width: 100%;
        }
    }

    .modal-content {
        overflow: hidden;
    }

    .modal-body {
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .onetrust-dialog-cookie-banner {
        display: flex;
        align-items: center;
        height: rem(50);
        background: $secondary-color;
        color: $primary-color;
        padding-left: rem(40);
        margin-bottom: rem(20);
        font-size: rem(13);

        @include media-breakpoint-down(md) {
            padding-left: rem(20);
        }

        button {
            color: $primary-color;
            text-decoration: underline !important;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

#onetrust-pc-sdk {
    /* Get rid of the margin OneTrust forces on cookie settings links */
    #ot-pc-desc .onetrust-do-not-sell-link {
        margin-left: 0;
    }

    /* Cookie drawers do not have any padding below them */
    #ot-pc-content {
        padding-bottom: rem(24);
    }
}
