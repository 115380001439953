.carousel-stripe-banner {
    background-color: $stripe-background;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zindex-low;
    height: $stripe-banner-height;
    position: relative;

    .globale-free-shipping-banner {
        margin-right: rem(70);
    }

    .m-banner-sticky & {
        background-color: $stripe-background-default;
    }

    .rendering-template.carousel-item:not(.active):not(.carousel-item-next) {
        display: none;
    }

    .carousel-inner {
        width: auto;
        flex: 1;
        text-align: center;
    }

    .carousel-item {
        padding: rem(12);
        line-height: rem(20);

        a {
            color: $stripe-text-color;
            font-size: $font-size-14;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            letter-spacing: rem(0.96);
            line-height: rem(19.2);
        }
    }

    svg {
        width: rem(16);
        height: rem(16);

        &:hover {
            stroke: $stripe-icon-color;
        }
    }

    button {
        padding: 0 rem(14);
    }

    .close-banner {
        padding-left: rem(40);

        .close {
            opacity: 1;
            color: $stripe-icon-color;

            &:hover {
                svg {
                    stroke: $stripe-icon-color;
                }
            }
        }
    }
}

.m-banner-sticky .carousel-stripe-banner-container {
    position: sticky;
    top: 0;
    z-index: $zindex-highest;
}