@mixin imageTextCenter {
    display: block !important;
    padding-bottom: 0;
    margin-bottom: rem(36);

    .lca-hero {
        display: block;
        width: 100%;
        height: 100%;
    }


    .text-container {
        position: absolute;
        height: 100%;
        top: 0;
        width: 56%;
        right: rem(43);
        max-height: rem(624);

        img {
            max-height: rem(624);
            height: 100%;
        }
    }
}

.content-tile {
    position: relative;
    padding-bottom: 3rem;

    &.cta-below {
        flex-direction: column;

        &.bottom-left {
            align-items: flex-start;
        }

        &.bottom-center {
            align-items: center;
        }

        &.bottom-right {
            align-items: flex-end;
        }

        &.middle-left {
            align-items: flex-start;
        }

        &.middle-center {
            align-items: center;
        }

        &.middle-right {
            align-items: flex-end;
        }

        &.top-left {
            align-items: flex-start;
        }

        &.top-center {
            align-items: center;
        }

        &.top-right {
            align-items: flex-end;
        }
    }

    &.cta-top {
        .text-container {
            position: absolute;
        }

        &.mob-top {
            justify-content: center;
            align-items: flex-start;
        }

        &.mob-center {
            justify-content: center;
            align-items: center;
        }

        &.mob-bottom {
            justify-content: center;
            align-items: flex-end;
        }
    }

    .description {
        font-family: $font-medium;
        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: rem(24);
        text-align: center;

        &.title-description {
            font-size: rem(14);
            line-height: rem(16);
            margin-top: rem(16);
        }
    }

    .title {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $font-medium;
            font-size: rem(20);
            line-height: rem(24);
            text-transform: uppercase;
            text-align: center;
            margin-bottom: rem(24);
        }
    }

    .cta-link {
        font-family: $font-medium;
        font-size: rem(14);
        line-height: rem(20);
        text-decoration: underline;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .lca-hero {
        display: block;
        width: 100%;
        height: 100%;
        max-height: rem(624);

        img {
            max-height: rem(624);
        }
    }

    /* For imageTextTemplateSplit.isml */
    &.image-text-split {
        &.cta-top {
            display: flex;

            .text-container {
                width: 56%;
                right: rem(43);
                max-height: rem(624);

                img {
                    max-height: rem(624);
                }
            }

            .lca-text-content {
                position: absolute;
                width: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &.mob-top {
                flex-direction: column-reverse;
            }

            &.mob-center {
                @include media-breakpoint-down(sm) {
                    @include imageTextCenter;
                }
            }

            &.mob-bottom {
                flex-direction: column;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .content-tile {
        &.cta-top {
            &.bottom-left {
                justify-content: flex-start;
                align-items: flex-end;
            }

            &.bottom-center {
                justify-content: center;
                align-items: flex-end;
            }

            &.bottom-right {
                justify-content: flex-end;
                align-items: flex-end;
            }

            &.middle-left {
                justify-content: flex-start;
                align-items: center;
            }

            &.middle-center {
                justify-content: center;
                align-items: center;
            }

            &.middle-right {
                justify-content: flex-end;
                align-items: center;
            }

            &.top-left {
                justify-content: flex-start;
                align-items: flex-start;
            }

            &.top-center {
                justify-content: center;
                align-items: flex-start;
            }

            &.top-right {
                justify-content: flex-end;
                align-items: flex-start;
            }

            /* For imageTextTemplateSplit.isml */
            &.image-text-split {
                &[class*='-left'] {
                    flex-direction: row-reverse;
                }

                &[class*='-right'] {
                    flex-direction: row;
                }

                &.middle-center,
                &.top-center,
                &.bottom-center {
                    @include imageTextCenter;
                }

                &[class*='middle-'] {
                    .lca-text-content {
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &[class*='top-'] {
                    .lca-text-content {
                        top: 0;
                        transform: translateX(-50%);
                    }
                }

                &[class*='bottom-'] {
                    .lca-text-content {
                        top: auto;
                        bottom: 0;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

.full-row-slot {
    &.category-marketing-slot {
        .image-text-split {
            &.cta-top {
                .text-container {
                    height: 60%;
                    text-align: center;
                    width: 73%;
                    right: 5%;
                    top: 15%;

                    @include media-breakpoint-up(md) {
                        width: 29%;
                        height: 45%;
                        right: 10%;
                        top: 15%;
                    }

                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}