// Variables
$mint: #b2fce4;

// Common
.afterpay-paragraph {
  font-size: rem(12);

  .afterpay-logo {
    height: rem(16);
  }
}

// Info Dialog
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    background: $white !important;
    height: rem(653) !important;
    padding-bottom: 0 !important;

    @include media-breakpoint-down(xs) {
      height: 100% !important;
    }
  }

  .afterpay_modal_wrapper {
    * {
      background: $white !important;
      font-family: $gotham-regular !important;
    }

    .row-0 {
      height: rem(148) !important;

      @include media-breakpoint-down(xs) {
        height: rem(100) !important;
      }
    }

    @include media-breakpoint-down(xs) {
      .grid-container {
        width: 100%;
        padding-top: 0 !important;

        .row-1 {
          height: auto !important;
          padding-top: rem(0.5) !important;

          .afterpay-modal-headline {
            display: inline;
            font-size: rem(22) !important;
            font-weight: 1000 !important;

            span {
              display: inline !important;
            }
          }
        }

        .process {
          height: rem(50) !important;

          .process-icon-container {
            width: rem(50) !important;

            .process-icon {
              min-height: rem(50) !important;
              max-height: rem(50) !important;
              margin-right: 0 !important;
            }
          }

          .process-subheading {
            font-size: rem(13) !important;

            br {
              display: none !important;
            }
          }
        }

        .row-3 {
          margin-top: rem(32) !important;

          .disclaimer {
            line-height: 1.5 !important;
          }
        }
      }
    }
  }

  .afterpay-modal-logo > svg {
    background: $mint !important;
    border-radius: rem(144);
    padding: rem(28) rem(35);

    @include media-breakpoint-down(xs) {
      width: rem(177) !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
    }
  }
}

// Cart
.cart-totals {
  .afterpay-paragraph {
    margin-top: rem(16);
    margin-bottom: 0;
  }
}

// Checkout
.afterpay_pbi-content {
  margin-top: rem(16);
}

.afterpay-link {
  a {
    text-decoration: underline;
  }

  .afterpay-payments-message {
    line-height: rem(20);
    margin-top: 2em;
    text-align: center;
  }
}

// Checkout Widget (typo is OOTB)
#afterpaInstallmentChart {
  margin-top: 1em;

  td {
    font-size: 0.9em;
  }

  .pie {
    border-radius: 50%;
    box-shadow: 0 0 0 2px #000 inset;
    stroke: none;
    width: 2.8125em;
  }

  .pie path {
    fill: $mint;
    stroke: none;
  }

  .pie .circle {
    fill: #fff;
    stroke: none;
  }

  .pie circle {
    fill: $mint;
  }

  .installment-price {
    font-size: 1.2em;
    font-weight: 600;
  }
}

.afterpay-tagline {
  margin-top: 1em;
}

.afterpay-redirect-message {
  font-style: italic;
  line-height: rem(20);
  margin-top: 2em;
  text-align: center;

  @include media-breakpoint-down(xs) {
    padding-bottom: rem(10);
  }
}
