.product-tile {
    overflow-x: auto;
    text-align: center;
    z-index: 0;
    overflow-y: hidden;
    min-height: auto;

    @include media-breakpoint-up(md) {
        position: relative;
    }

    // to prevent sticky header overlapping
    &-container .product {
        scroll-margin-top: rem(105);

        @include media-breakpoint-down(md) {
            scroll-margin-top: rem(90);
        }
    }

    .carousel-control {
        top: 0;
        bottom: 0;
        height: rem(48);
        opacity: 0;
        margin: auto;
        transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;

        &-prev {
            padding-left: rem(8);
        }

        &-next {
            padding-right: rem(8);
        }
    }

    .image-container {
        position: relative;
        text-align: center;
        min-height: rem(209);

        @include media-breakpoint-up(md) {
            min-height: rem(252);
        }

        @include media-breakpoint-up(lg) {
            min-height: auto;
            overflow-y: hidden;
        }

        .carousel-inner {
            min-height: auto;

            @include media-breakpoint-down(md) {
                overflow: unset;
            }
        }

        .tile-img-link {
            position: relative;


            img {
                width: 100%;
                display: block;
                aspect-ratio: 170 / 255; // to imitate placeholder while image are loading

                &.lz-loaded {
                    aspect-ratio: unset;
                }
            }

            &:focus {
                box-shadow: none;
            }
        }

        .quickview {
            font-family: $gotham-regular;
            font-size: $font-size-12;
            line-height: rem(16);
            letter-spacing: rem(0.4);
            opacity: 0;
            position: absolute;
            bottom: rem(16);
            left: rem(10);
            width: calc(100% - rem(20));
            padding: rem(12) 0;
            text-decoration: none;
            background-color: $quick-view-button-background-color;
            border: rem(1) solid $quick-view-button-border-color;
            color: $quick-view-text-color;
            z-index: $zindex-lowest;
            transition: opacity 0.3s;

            &:hover {
                text-decoration: none;
            }
        }

        &:hover {

            .quickview,
            .quickbuy-btn {
                opacity: 1;
            }

            @include media-breakpoint-up(md) {

                .product-tile-badge,
                .loyalty-product-tile-badge {
                    opacity: 0;
                }
            }

            .alt-img {
                display: block;
            }

            .carousel-control {
                opacity: 1;
            }
        }

        .quickview:focus {
            opacity: 1;
            box-shadow: none;
        }

        .carousel-control:focus {
            box-shadow: none;
        }
    }

    .pdp-link {
        color: $grey-text;
        font-family: $crimson;
        font-size: $font-size-14;
        letter-spacing: 0.08em;
        line-height: rem(20);
        text-align: left;
        text-transform: uppercase;

        .link {
            line-height: rem(20);

            &:hover {
                text-decoration: none;
            }

            img {
                height: rem(260);
                margin-bottom: rem(4);
            }
        }
    }

    .tile-body {
        padding: 0 0 rem(32);

        @include media-breakpoint-up(md) {
            padding: rem(16) 0 rem(64);
        }

        .quickbuy-btn {
            @include media-breakpoint-up(lg) {
                display: none;
            }

            opacity: 1;
            position: unset;
            margin-top: 1rem;
            width: 100%;
        }

        .pdp-link {
            color: $primary-black;
            width: 100%;
            float: left;
            height: rem(40);

            @include media-breakpoint-up(lg) {
                height: auto;
            }

            a {
                color: inherit;
                font-size: $font-size-12;
                line-height: rem(16);
                text-decoration: none;
                letter-spacing: 0.08em;
                margin: 1px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-wrap: balance;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;

                @include media-breakpoint-up(md) {
                    font-size: $font-size-14;
                    line-height: rem(20);
                }
            }

            h3 {
                font-size: $font-size-14;
                font-family: $font-primary;
                line-height: rem(20);
                font-weight: $font-weight-regular;
                margin: 0;
                width: 80%;
                float: left;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: balance;


                @include media-breakpoint-up(lg) {
                    display: inline;
                    text-overflow: none;
                }
            }

            .add-to-wish-list-container {
                width: 20%;
                float: right;
                text-align: right;
            }
        }

        .price {
            font-family: $font-medium;
            font-size: $font-size-14;
            line-height: rem(20);
            letter-spacing: 0.08em;
            text-align: left;
            margin-bottom: rem(8);
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            color: $primary-black;
            min-height: rem(40);
            clear: both;

            @include media-breakpoint-up(md) {
                font-size: $font-size-3;
                min-height: auto;
            }

            del+.sales {
                color: $sale-price-color;
            }
        }

        .color-swatches {
            text-align: left;
            min-height: rem(32);
            display: flex;

            .swatch-circle {
                width: rem(16);
                height: rem(16);
                margin-right: 0;
                border: none;

                @include media-breakpoint-up(md) {
                    width: rem(24);
                    height: rem(24);
                }
            }

            .swatch-border {
                border: rem(0.5) solid $clay;
            }

            .swatches {
                flex-wrap: wrap;

                &-more-colors {
                    font-size: $font-size-12;
                    line-height: rem(24);
                    letter-spacing: rem(0.4);
                    text-decoration: underline;
                    text-underline-offset: rem(2.5);
                    text-transform: uppercase;
                }
            }

            .swatches-empty {
                font-family: $gotham-semibold;
                font-size: $font-size-12;
                line-height: rem(16);
                color: $price-color;
                letter-spacing: rem(0.4);
                text-align: left;
                margin-bottom: rem(8);
            }

            .product-tile-swatch {
                margin-right: rem(12);
                padding: rem(1);
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-up(md) {
                    margin-right: rem(15);
                }

                &.selected {
                    border: rem(1) solid $primary-black;
                    border-radius: 100%;
                    width: rem(24);
                    height: rem(24);
                    position: relative;

                    @include media-breakpoint-up(sm) {
                        border: rem(0.5) solid $primary-black;
                    }

                    @include media-breakpoint-up(md) {
                        width: rem(32);
                        height: rem(32);
                    }

                    img {
                        width: rem(16);
                        height: rem(16);

                        @include media-breakpoint-up(md) {
                            width: rem(24);
                            height: rem(24);
                        }
                    }
                }
            }
        }
    }

    .product-tile-badge,
    .loyalty-product-tile-badge {
        padding: rem(8);
        background: $light-modal-background-color;
        position: absolute;
        right: rem(8);
        bottom: rem(43);
        z-index: $zindex-earlyaccess-badge;

        @include media-breakpoint-up(md) {
            bottom: rem(8);
        }

        @include media-breakpoint-only(md) {
            bottom: rem(25);
        }

        &-text {
            font-size: $font-size-10;
            line-height: normal;
            letter-spacing: 0.05em;
            color: $grey-text;
            font-family: $font-primary;
            text-transform: uppercase;
        }
    }

    .product-tile-badge {
        z-index: $zindex-lowest;
    }

    .loyalty-product-tile-badge {
        background: $loyalty-badge-background-color;

        .loyalty-product-tile-badge-text {
            color: $loyalty-badge-text-color;
        }
    }
}

.add-to-wish-list,
.added-to-wish-list,
.loyalty-early-access-locked {
    width: rem(28);
    height: rem(28);
    z-index: $zindex-low;
    right: rem(16);
    font-size: inherit;
    line-height: normal;
    border: none;
}

.loyalty-early-access-locked {
    width: rem(30);
    padding-right: 0;

    @include media-breakpoint-up(md) {
        width: rem(24);
        padding-right: rem(6);
    }
}

.add-to-wish-list {
    .svg-wishlist-icon {
        display: block;
    }

    .svg-wishlist-active-icon {
        display: none;
    }
}

.added-to-wish-list {
    .svg-wishlist-icon {
        display: none;
    }

    .svg-wishlist-active-icon {
        display: block;
    }
}

.product-grid-container {
    .wishlist-add-update {

        .svg-wishlist-icon,
        .svg-wishlist-active-icon {
            width: rem(17.4);
            height: rem(15.3);

            @include media-breakpoint-up(md) {
                width: rem(20.3);
                height: rem(17.85);
            }
        }
    }
}

.price {
    .list+.sales {
        margin-left: rem(8);
    }

    del:not(.d-none)+.sales {
        color: $sale-price-color;
        margin-left: rem(8);

        .custom-set-detail-modal & {
            margin-left: 0;
        }
    }

    .percent-off+.sales {
        display: block;
        color: $sale-price-color;
    }

    .pricing {
        color: $price-color;
    }

    .strike-through {
        &:not(.non-adjusted-price) {

            +.pricing,
            +.price-section .sales {
                color: $sale-price-color;
            }
        }
    }

    .list-range {

        .list+.price-section,
        .price-section+.price-section {
            .sales {
                color: $sale-price-color;
                margin-left: rem(8);
            }
        }

        .percent-off+.price-section .sales {
            display: block;
            color: $sale-price-color;
        }

        .range-price-section {
            +.price-section .sale-selected {
                display: block;
                margin-left: 0;
            }
        }

        .price-section .sale-selected-range.sale-selected {
            display: block;
            margin-left: 0;
            font-size: $font-size-14;
        }
    }

    .strike-through {
        color: $primary-black;
        margin-right: 0;
    }

    .sales {
        color: $primary-black;
    }

    .sale-selected {
        color: $sale-price-color;
    }

    .percent-off {
        font-size: $font-size-10;
        margin-left: rem(8);
        color: $percent-off-color;
        vertical-align: top;
        font-family: $gotham-regular;
    }

    .starting,
    .range,
    .sales {
        font-weight: $font-weight-medium;
    }

    .range {
        .sales {
            color: $primary-black;
        }

        del:not(.d-none)+.sales {
            color: $sale-price-color;
        }
    }

    .price-section {
        .sale-selected {
            &.item-adjusted {
                font-size: $font-size-14;
                margin-left: 0;
                flex-basis: 100%;
            }
        }
    }
}

.einstein-carousel-new,
.search-results {
    .price {
        .price-section {
            flex-wrap: wrap;

            del:not(.d-none) {
                margin-right: rem(8);
            }

            del:not(.d-none)+.sales {
                margin-left: 0;

                .custom-set-detail-modal & {
                    margin-left: 0;
                }
            }
        }
    }
}

.attributes .attribute,
.attribute {
    .selected-swatch-name {
        font-family: $crimson;
        font-size: $font-size-14;
        line-height: rem(20);
    }

    .is-on-sale {
        .color-attribute {
            &::after {
                content: '';
                width: rem(4);
                height: rem(4);
                background-color: $sale-swatch-indicator-color;
                position: absolute;
                border-radius: 50%;
                top: 0;
                right: 0;
            }
        }
    }

    .color-list {
        margin-bottom: rem(32);
        margin-right: rem(16);
    }

    .color-attribute {
        height: rem(40);
        width: rem(40);
        position: relative;

        span.swatch-circle {
            height: rem(32);
            width: rem(32);
            border-radius: 50%;
            margin-right: 0;
            position: static;
            vertical-align: middle;
            display: inline-block;
            background-size: contain;

            &.selected {
                &::after {
                    border: rem(0.5) solid $selecte-swatch-border-color;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                    content: '';
                    background: transparent;
                    position: absolute;
                }
            }

            &.unselectable {
                cursor: pointer;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.final-sale-message {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    margin-top: rem(16);
    line-height: rem(16);
    letter-spacing: 0.05em;
    color: $availability-error-message-color;
    max-width: rem(375);
}

.availability-message .availability-message-text {
    color: $availability-error-message-color;
    font-size: $font-size-12;
    max-width: rem(375);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .plus-more-colors {
        white-space: nowrap;
        overflow: hidden;
    }
}

.add-to-cart-messages {
    z-index: $zindex-highest;
    top: rem(134);
    transform: translate(-47%, -50%);
    left: 47%;

    .add-to-basket-alert {
        border-radius: 0;
        border: none;
        background-color: $add-to-cart-message-background;
        color: $add-to-cart-message-color;
        font-family: $gotham-regular;
        font-size: $font-size-14;
        line-height: rem(20);
    }
}

@include media-breakpoint-down(sm) {
    .add-to-cart-messages {
        transform: none;
        left: initial;
        top: rem(48);
    }
}

.primary-images {
    .carousel-item {
        position: relative;

        @include media-breakpoint-up(md) {
            padding-top: unset;
        }

        .video-component,
        img {
            @include media-breakpoint-up(md) {
                position: unset;
            }
        }
    }
}

.product-tile {
    .carousel-item {
        padding-top: 0;
        position: relative;

        .video-component,
        img {
            opacity: 0;

            @include media-breakpoint-up(md) {
                position: unset;
            }

            &.lz-loaded {
                animation: fadeIn 0.1s ease-in-out forwards;

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: scale(0.9);
                    }

                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

.show-filter-sort-header {
    .sort-order-dropdown {
        margin-left: auto;
    }

    .sort-order-menu {
        width: rem(318);
    }

    .sort-order-menu-toggle {
        float: right;
        text-transform: uppercase;
        font-family: $font-medium;
        font-size: $font-size-14;
        line-height: rem(20);
        color: $primary-black;
        letter-spacing: 0.08em;
        font-weight: $font-weight-medium;

        span {
            padding-left: 0;
        }
    }

    .result-count {
        float: left;
        padding: 0;
        letter-spacing: 0.07em;
    }
}

.product-tile {
    .carousel-item {
        padding-top: 0;
    }

    .carousel-indicators {
        &.jw-plp {
            margin: rem(12) auto rem(16) auto;
            position: relative;
            z-index: $zindex-lowest;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }

            .einstein-carousel-new & {
                @include media-breakpoint-up(md) {
                    display: none;
                }

                @include media-breakpoint-down(sm) {
                    display: flex;
                    margin: rem(15) 0 rem(10);
                }
            }

            li {
                width: rem(6);
                height: rem(6);
                border: rem(0.5) solid $primary-grey;
                border-radius: 50%;
                margin: 0 rem(6);

                &.active {
                    background: $primary-grey;
                }
            }
        }
    }
}

.neon-badge-indicator {
    color: $swatch-neon-color;
    font-size: $font-size-10;
    line-height: rem(16);
    font-weight: $font-weight-regular;
    margin-top: rem(4);
    text-align: center;

    p {
        margin: 0;
    }
}

.flex {
    display: flex;
}

.quickbuy-btn {
    font-family: $font-medium;
    font-weight: $font-weight-medium;
    font-size: $font-size-14;
    line-height: rem(20);
    letter-spacing: 0.08em;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: rem(12) 0;
    text-decoration: none;
    background-color: $quick-buy-button-background-color;
    border: rem(1) solid $quick-view-button-border-color;
    color: $quick-view-text-color;
    z-index: $zindex-lowest;
    transition: opacity 0.3s;
    cursor: pointer;
    margin-top: rem(16);
    text-transform: uppercase;

    &:hover {
        &:not(.qmob) {
            text-decoration: none;
            opacity: 0;
        }
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        bottom: rem(16);
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        width: calc(100% - rem(32));
    }
}

.quick-buy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: rem(8) rem(12);
    color: $quick-buy-button-text-color;
    z-index: $zindex-highest;
    margin-top: 0;

    @include media-breakpoint-up(lg) {
        top: unset;
        bottom: rem(16);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: calc(100% - rem(32));
        background-color: $quick-buy-button-background-color;
        padding: rem(16) 0 rem(24) 0;
    }

    .non-color-container {
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: center;
        margin: 0 0 rem(8) rem(20);

        @include media-breakpoint-up(md) {
            justify-content: space-around;
            margin: 0;
        }

        .fitsize-swatches {
            margin-right: rem(48);

            @include media-breakpoint-up(md) {
                margin-right: 0;
                margin-bottom: rem(22);
            }

            .fit-product-type {
                font-family: $font-medium;
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
                color: $primary-black;
                height: unset;
                line-height: rem(20);
                letter-spacing: 0.08em;

                &.selected {
                    border-bottom: rem(1.5) solid $primary-black;
                }
            }
        }
    }

    .size-container {
        display: flex;
        gap: rem(40);
        justify-content: left;
        flex-direction: row;
        align-items: center;
        margin: rem(48) rem(28) 0;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            justify-content: space-around;
            margin: rem(8) rem(16) 0 rem(16);
            gap: rem(32);
        }

        .size-list {
            margin-right: 0;

            .size-btn {
                color: $primary-black;
                font-family: $font-medium;
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
                height: inherit;
                line-height: rem(20);
                letter-spacing: 0.08em;

                &.not-available {
                    text-decoration: line-through;
                    color: $primary-grey;
                }
            }
        }
    }

    ul.non-color-container {
        &~.size-container {
            margin: rem(24) rem(20) rem(12);

            @include media-breakpoint-up(md) {
                margin: rem(8) rem(16) 0 rem(16);
            }
        }
    }
}

.quick-buy-modal {
    .modal-dialog {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        background-color: $white;
        min-height: rem(150);
        height: auto;
        max-width: unset;
        animation: fadeIn 0.3s ease-in-out forwards;

        .modal-content {
            border: 0;

            .generic-confirmation-header {
                .close {
                    color: $primary-black;
                    margin: rem(12) rem(12) 0 0;
                    height: rem(24);
                    width: rem(24);
                    opacity: 1;

                    svg {
                        height: rem(16);
                        width: rem(16);
                        color: $primary-black;
                        opacity: 1;
                    }

                    &:hover {
                        svg {
                            stroke: $primary-black;
                        }
                    }
                }
            }

            .modal-body {
                padding: 0 0 rem(28) 0;
                margin: 0;

                .quick-buy {
                    padding-left: 0;
                    padding-right: 0;
                    position: relative;

                    .size-container {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .select-size-label {
            font-family: $font-medium;
            font-size: $font-size-3;
            line-height: rem(20);
            font-weight: $font-weight-medium;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin: 0 rem(12) rem(24);
            text-align: left;
            color: $primary-black;
        }

    }

    &.show {
        .modal-dialog {
            @keyframes fadeIn {
                from {
                    opacity: 0;
                    bottom: rem(-250);
                    transform: scale(0.9);
                }

                to {
                    opacity: 1;
                    bottom: 0;
                    transform: scale(1);
                }
            }
        }
    }
}

.quick-buy-add-toast {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-max-highest;
    background-color: $white;
    height: rem(124);
    overflow: hidden;
    box-shadow: 0 rem(4) rem(8) 0 $box-shadow-color;

    .image-container {
        width: rem(84);
        height: rem(124);
        margin-right: rem(16);
        overflow: hidden;

        .p-img {
            width: auto;
            height: 100%;
        }
    }

    .right-slot {
        margin-top: rem(16);

        p {
            margin-bottom: rem(4);
            font-family: $font-primary;
            line-height: rem(16);
            letter-spacing: rem(0.6);
            font-weight: $font-weight-regular;
            font-size: $font-size-12;
        }

        .heading {
            p {
                font-family: $font-medium;
                line-height: rem(20);
                letter-spacing: rem(1.12);
                text-transform: uppercase;
                font-weight: $font-weight-medium;
                margin-bottom: rem(12);
                font-size: $font-size-14;
            }

            .close {
                padding-right: rem(18);
            }
        }
    }
}

.product-recommendations {
    .scrollable-content:not(.einstein-carousel-new) {
        .product {
            .product-tile {
                .image-container {
                    .quickbuy-btn {
                        display: none;
                    }
                }
            }
        }
    }
}