/*
    Usage:
    <label class="switch">
        <span class="sr-only">Pick Up in Store</span>
        <input type="checkbox" />
        <span class="slider"></span>
    </label>
*/
// large switch
$large-switch-width: rem(48);
$large-switch-height: rem(24);
$large-handle:rem(18);


// small switch
$small-switch-width: rem(32);
$small-switch-height: rem(18);
$small-handle:rem(10);

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: $large-switch-width;
    height: $large-switch-height;
    margin-bottom: rem(0);

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $switch-on-bg-color;
            border: none;
                &:before{
                background: $slider-on-bg-color;
                border: none;
            }
        }

        &:checked + .slider:hover{
            background-color: $switch-on-hover-bg-color;
            border: none;
            &:before{
                background: $slider-on-bg-color;
                border: none;
            }
        }

        &:checked + .slider:disabled{
            background-color: $switch-disabled-bg-color;
            border: none;
            &:before{
                background-color: $slider-disabled-bg-color;
                border: none;
            }
        }

        &:focus + .slider {
            box-shadow: 0px 0px 9px 0px $dark-teal;
        }

        &:checked + .slider:before {
            transform: translateX(rem(20));
        }

        &:hover + .slider{
            border: rem(1) solid $swich-off-hover-border-color;
            &:before{
                background-color: $slider-off-hover-bg-color;
                border: none;
            }
        }
    }
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: $switch-off-bg-color;
    border-radius: rem(12);
    border: rem(1) solid $switch-off-border-color;

    &:before {
        position: absolute;
        content: '';
        margin-top: rem(2.5);
        margin-left: rem(2);
        margin-right: rem(2);
        height: $large-handle;
        width: $large-handle;
        transition: 0.4s;
        background-color: $slider-off-bg-color;
        border-radius: 50%;
        border: rem(1) solid $slider-off-bg-color;
    }
}
