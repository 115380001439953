.veil {
    $spinner-size: 48; // in px, height and width of loading spinner container

    .underlay {
        opacity: 0.4;
        background-color: $spinner-background-color;
    }

    .spinner {
        background-image: url('./../images/loading.svg');
        background-size: 100%;
        animation: none;
        width: rem($spinner-size);
        height: rem($spinner-size);
        margin-top: -(rem($spinner-size / 2));
        margin-left: -(rem($spinner-size / 2));

        .dot1,
        .dot2 {
            display: none;
        }
    }
}
